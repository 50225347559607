import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { backBtnIcon, desktopWarningIcon } from "../../icon/icon";

import ProductDetailing from "./ProductDetailing";
import ProductSampling from "./ProductSampling";
import MarketResearch from "./MarketResearch";
import JointCall from "./JointCall";
import CallSummary from "./CallSummary";
import PostCall from "./PostCall";
import DataNotFound from "../../components/Common/DataNotFound";
import Loader from "../../components/Common/Loader";
import { handleVisits } from "../../helpers/commonFunction";

// APIs
import {
  reqToAddJointCall,
  reqToAddMDSignature,
  reqToAddMarketResearch,
  reqToAddProductDetailing,
  reqToAddProductSampling,
  reqToGetCallActivitieData,
  reqToGetCallSummaryData,
  reqToGetJointCallData,
  reqToGetMarketResearchData,
  reqToGetProductDetailingData,
  reqToGetProductSamplingData,
  resetActivitiyObject,
} from "../../store/slice/callRecordingSlice";
import { routes } from "../../helpers/constants";
import { reqToUpdateQuickSign } from "../../store/slice/quickSignSlice";

const CallActivities = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const callActivitieData =
    callRecordingReducer && callRecordingReducer.callActivitieData;
  const activities = callRecordingReducer && callRecordingReducer.activities;
  const productDObj = callRecordingReducer && callRecordingReducer.productDObj;
  const marketRObj = callRecordingReducer && callRecordingReducer.marketRObj;
  const productSObj = callRecordingReducer && callRecordingReducer.productSObj;
  const jointCObj = callRecordingReducer && callRecordingReducer.jointCObj;
  const mdDetails = callRecordingReducer && callRecordingReducer.mdDetails;
  const mdBehavior = callRecordingReducer && callRecordingReducer.mdBehavior;

  const [method, setMethod] = useState("");
  const [selectedActivity, setSelectedActivity] = useState();
  const [callActivitieList, setCallActivitieList] = useState();
  const [showActivitySidebars, setShowActivitySidebars] = useState({
    productDetailing: false,
    marketResearch: false,
    productSampling: false,
    jointCall: false,
  });

  // Sidebar states
  const [showCallSummary, setShowCallSummary] = useState(false);
  const [showPostCall, setShowPostCall] = useState(false);

  // Handle callSummary sidebar
  const handleShowCallSummary = () => {
    setShowCallSummary(!showCallSummary);
  };

  // Handle postcall sidebar
  const handleShowPostCall = () => {
    setShowPostCall(!showPostCall);
  };

  // Get call activities data by selected MD
  useEffect(() => {
    if (location && location?.state?.selectedMD) {
      dispatch(
        reqToGetCallActivitieData({
          data: location?.state?.reqObj,
        })
      );
    }
    if (location && location?.state?.prevPath !== location.pathname) {
      dispatch(resetActivitiyObject());
    }
  }, []);

  useEffect(() => {
    if (callActivitieData && callActivitieData?.activity?.length > 0) {
      updateCallActivitieData();
    }
  }, [callActivitieData]);

  // Update Call activities data
  const updateCallActivitieData = (name) => {
    if (name) {
      const newActivityData =
        callActivitieList &&
        callActivitieList.map((item) => {
          if (item.name === name) {
            return {
              ...item,
              isCheck: true,
              selectedData:
                name === activities.productD
                  ? productDObj
                  : name === activities.marketR
                  ? marketRObj
                  : name === activities.productS
                  ? productSObj
                  : [jointCObj],
            };
          } else {
            return item;
          }
        });
      setCallActivitieList(newActivityData);
    } else {
      const newActivityData = callActivitieData?.activity.map((item) => {
        return {
          ...item,
          isCheck: false,
          selectedData: [],
        };
      });
      setCallActivitieList(newActivityData);
    }
  };

  // Handle show sidebar
  const handleCallActivitiesSidebar = (item) => {
    if (item && item) {
      setSelectedActivity(item);
    }
    if (item?.name === activities.productD) {
      handleProductDetailingData();
    } else if (item?.name === activities.marketR) {
      handleMarketResearchData();
    } else if (item?.name === activities.productS) {
      handleProductSamplesData();
    } else if (item?.name === activities.jointCall) {
      handleJointCallData();
    } else {
      setShowActivitySidebars({
        ...showActivitySidebars,
        productDetailing: false,
        marketResearch: false,
        productSampling: false,
        jointCall: false,
      });
    }
  };

  // Get product detailing data
  const handleProductDetailingData = () => {
    dispatch(
      reqToGetProductDetailingData({
        data: location?.state?.reqObj,
        onSuccess: () => {
          setShowActivitySidebars({
            ...showActivitySidebars,
            productDetailing: true,
            marketResearch: false,
            productSampling: false,
            jointCall: false,
          });
        },
      })
    );
  };

  // Get market research data
  const handleMarketResearchData = () => {
    dispatch(
      reqToGetMarketResearchData({
        data: location?.state?.reqObj,
        onSuccess: () => {
          setShowActivitySidebars({
            ...showActivitySidebars,
            productDetailing: false,
            marketResearch: true,
            productSampling: false,
            jointCall: false,
          });
        },
      })
    );
  };

  // Get product samples data
  const handleProductSamplesData = () => {
    dispatch(
      reqToGetProductSamplingData({
        data: location?.state?.reqObj,
        onSuccess: () => {
          setShowActivitySidebars({
            ...showActivitySidebars,
            productDetailing: false,
            marketResearch: false,
            productSampling: true,
            jointCall: false,
          });
        },
      })
    );
  };

  // Get joint call data
  const handleJointCallData = () => {
    dispatch(
      reqToGetJointCallData({
        data: location?.state?.reqObj,
        onSuccess: () => {
          setShowActivitySidebars({
            ...showActivitySidebars,
            productDetailing: false,
            marketResearch: false,
            productSampling: false,
            jointCall: true,
          });
        },
      })
    );
  };

  // Handle show call activities btn sidebar
  const handleCallSummaryData = () => {
    handleShowCallSummary();
  };

  // Handle last APIs data submit
  const handleAllCallRecordingData = (data) => {
    const productDOBJ =
      productDObj &&
      productDObj.map(({ product_id, rank }) => ({
        product_id,
        rank,
      }));

    const marketROBJ =
      marketRObj &&
      marketRObj.map((item) => {
        if (item.answer_id) {
          return {
            answer_id: item.answer_id,
            question_id: item.question_id,
          };
        } else {
          return {
            answer_text: item.answer_text,
            question_id: item.question_id,
          };
        }
      });
    const productSOBJ =
      productSObj &&
      productSObj.map(({ promomat_id, quantity }) => ({
        promomat_id,
        quantity,
      }));

    dispatch(
      reqToGetCallSummaryData({
        data: {
          md_id: location?.state?.reqObj?.md_id,
          is_virtual: method ? true : false,
        },
        onSuccess: (response) => {
          // product-detailing
          if (productDObj && productDObj.length > 0) {
            const productDObject = {
              call_id: response?.id,
              product: productDOBJ,
              activity_id:
                callActivitieData && callActivitieData?.activity[0]?.id,
            };
            dispatch(reqToAddProductDetailing({ data: productDObject }));
          }

          // market-research
          if (marketRObj && marketRObj.length > 0) {
            const marketRObject = {
              call_id: response?.id,
              research: marketROBJ,
              activity_id:
                callActivitieData && callActivitieData?.activity[1]?.id,
            };
            dispatch(reqToAddMarketResearch({ data: marketRObject }));
          }

          // product-sampling
          if (productSObj && productSObj.length > 0) {
            const productSObject = {
              call_id: response?.id,
              product: productSOBJ,
              activity_id:
                callActivitieData && callActivitieData?.activity[2]?.id,
            };
            dispatch(reqToAddProductSampling({ data: productSObject }));
          }

          // joint-call
          if (jointCObj && Object.keys(jointCObj).length > 0) {
            const jointObject = {
              call_id: response?.id,
              notes_id: jointCObj.notes_id,
              notes: jointCObj.notes,
              signature_url: jointCObj.signature_url,
              activity_id:
                callActivitieData && callActivitieData?.activity[3]?.id,
            };
            dispatch(reqToAddJointCall({ data: jointObject }));
          }

          // Update quickSign
          if (
            location?.state?.quickSignObj &&
            Object.keys(location?.state?.quickSignObj).length > 0
          ) {
            const quickSignObject = {
              call_id: response?.id,
              quick_sign_id: location?.state?.quickSignObj?.signature_id,
            };
            dispatch(reqToUpdateQuickSign({ data: quickSignObject }));

            const mdquickSign = {
              call_id: response?.id,
              signature_url: location?.state?.quickSignObj?.signature_url,
              post_call: data?.post_call,
              next_obj: data?.next_obj,
            };
            dispatch(reqToAddMDSignature({ data: mdquickSign }));
          } else {
            // MD Signature
            const mdSignObject = {
              call_id: response?.id,
              signature_url: mdDetails?.signature_url,
              post_call: data?.post_call,
              next_obj: data?.next_obj,
              md_behavior: mdBehavior && mdBehavior,
            };
            dispatch(reqToAddMDSignature({ data: mdSignObject }));
          }
          handleShowCallSummary();
          handleShowPostCall();
          navigate(routes.mdList);
          setMethod("");
        },
      })
    );
  };

  return (
    <>
      {callRecordingReducer && callRecordingReducer.loader && <Loader />}
      <div className="tc-body-section tc-sec-body-section">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">Call Activities</p>
          <Dropdown className="tc-social-platform-dropdown">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="tc-social-platform-dropdown-btn"
            >
              {desktopWarningIcon}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                defaultValue="Viber"
                onClick={(e) => setMethod("Viber")}
              >
                Viber
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                defaultValue="Zoom"
                onClick={(e) => setMethod("Zoom")}
              >
                Zoom
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                defaultValue="FB Messenger"
                onClick={(e) => setMethod("FB Messenger")}
              >
                FB Messenger
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                defaultValue="WhatsApp"
                onClick={(e) => setMethod("WhatsApp")}
              >
                WhatsApp
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="tc-md-list-common-detail">
          <div className="tc-md-list-common-detail-row">
            <span className="tc-md-list-common-detail-label">MD Name:</span>
            <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
              {callActivitieData &&
                callActivitieData?.firstname +
                  " " +
                  callActivitieData?.lastname}
            </p>
          </div>
          <div className="tc-md-list-common-detail-row">
            <span className="tc-md-list-common-detail-label">Specialty:</span>
            <p className="tc-md-list-common-detail-text">
              {callActivitieData && callActivitieData?.specialty_name
                ? callActivitieData?.specialty_name
                : "N/A"}
            </p>
          </div>
          <div className="tc-md-list-common-detail-row">
            <span className="tc-md-list-common-detail-label">Location:</span>
            <p className="tc-md-list-common-detail-text">
              {callActivitieData && callActivitieData?.location_name
                ? callActivitieData?.location_name
                : "N/A"}
            </p>
          </div>
          <div className="tc-md-list-common-detail-row">
            <span className="tc-md-list-common-detail-label">Visits:</span>
            <p className="tc-md-list-common-detail-text">
              {callActivitieData && callActivitieData?.target
                ? handleVisits(
                    callActivitieData?.actual_visits,
                    callActivitieData?.target
                  )
                : "N/A"}
            </p>
          </div>
          <div className="tc-md-list-common-detail-row">
            <span className="tc-md-list-common-detail-label">Objective:</span>
            <p className="tc-md-list-common-detail-text">
              {callActivitieData &&
                callActivitieData?.objective &&
                callActivitieData?.objective}
            </p>
          </div>
        </div>
        <Table className="tc-table">
          <thead>
            <tr>
              <th>Activity</th>
              <th>Done?</th>
            </tr>
          </thead>
          <tbody>
            {callActivitieList && callActivitieList?.length === 0 ? (
              <tr>
                <td colSpan="2">
                  <DataNotFound />
                </td>
              </tr>
            ) : (
              <>
                {callActivitieList &&
                  callActivitieList?.length > 0 &&
                  callActivitieList?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className="tc-md-list-table-heading">
                            {item?.name}
                          </td>
                          <td>
                            <button
                              className={`tc-activity-check-btn ${
                                item?.is_done
                                  ? "active pointer-none"
                                  : item.isCheck && "active"
                              }`}
                              onClick={() => {
                                handleCallActivitiesSidebar(item);
                              }}
                            ></button>
                          </td>
                        </tr>
                        <tr className="tc-md-list-table-product-sub-detail-box">
                          <td colSpan={2}>
                            {item?.selectedData &&
                              item?.selectedData?.length > 0 &&
                              item?.selectedData?.map((list) => {
                                return (
                                  <>
                                    {item.name === activities.productD && (
                                      <tr
                                        className="tc-md-list-table-product-detail-row"
                                        colSpan={2}
                                      >
                                        <td>
                                          <p>{list?.name}</p>
                                        </td>
                                        <td>
                                          <p>{list?.productValue}</p>
                                        </td>
                                      </tr>
                                    )}

                                    {item.name === activities.marketR && (
                                      <tr
                                        className="tc-md-list-table-product-detail-row"
                                        colSpan={2}
                                      >
                                        <td>
                                          <p>{list?.question}</p>
                                        </td>
                                        <td>
                                          <p>
                                            {list?.name
                                              ? list?.name
                                              : list?.answer_text}
                                          </p>
                                        </td>
                                      </tr>
                                    )}
                                    {item.name === activities.productS && (
                                      <tr
                                        className="tc-md-list-table-product-detail-row"
                                        colSpan={2}
                                      >
                                        <td>
                                          <p>{list?.name}</p>
                                        </td>
                                        <td>
                                          <p>{list?.quantity}</p>
                                        </td>
                                      </tr>
                                    )}
                                    {item.name === activities.jointCall && (
                                      <tr
                                        className="tc-md-list-table-product-detail-row"
                                        colSpan={2}
                                      >
                                        <td>
                                          <p>with {list?.name}</p>
                                        </td>
                                        <td>
                                          <p>{list?.quantity}</p>
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </>
            )}
          </tbody>
        </Table>
        {callActivitieList && callActivitieList?.length > 0 && (
          <div className="tc-md-list-detail-bottom-btn-content">
            <button
              type="button"
              className="tc-md-list-detail-bottom-btn"
              onClick={() => handleCallSummaryData()}
              disabled={
                productDObj?.length === 0 &&
                productSObj?.length === 0 &&
                marketRObj?.length === 0 &&
                jointCObj?.length === 0
              }
            >
              Sign
            </button>
          </div>
        )}
        {/* Product Detailing */}
        <ProductDetailing
          showsidebar={showActivitySidebars.productDetailing}
          selectedActivity={selectedActivity}
          handleCallActivitiesSidebar={handleCallActivitiesSidebar}
          updateCallActivitieData={updateCallActivitieData}
        />

        {/* Market Research */}
        <MarketResearch
          selectedActivity={selectedActivity}
          showsidebar={showActivitySidebars.marketResearch}
          handleCallActivitiesSidebar={handleCallActivitiesSidebar}
          updateCallActivitieData={updateCallActivitieData}
        />

        {/* Product Sampling */}
        <ProductSampling
          selectedActivity={selectedActivity}
          showsidebar={showActivitySidebars.productSampling}
          handleCallActivitiesSidebar={handleCallActivitiesSidebar}
          updateCallActivitieData={updateCallActivitieData}
        />

        {/* Joint Call */}
        <JointCall
          selectedActivity={selectedActivity}
          showsidebar={showActivitySidebars.jointCall}
          handleCallActivitiesSidebar={handleCallActivitiesSidebar}
          updateCallActivitieData={updateCallActivitieData}
        />

        {/* Call Summary */}
        <CallSummary
          method={method}
          showCallSummary={showCallSummary}
          selectedActivity={selectedActivity}
          handleShowPostCall={handleShowPostCall}
          quicksigndata={location?.state?.quickSignObj}
          handleShowCallSummary={handleShowCallSummary}
        />

        {/* Post Call */}
        <PostCall
          showPostCall={showPostCall}
          handleShowPostCall={handleShowPostCall}
          handleAllCallRecordingData={handleAllCallRecordingData}
        />
      </div>
    </>
  );
};

export default CallActivities;
