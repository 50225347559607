export const messages = {
  toast: {
    logout: "Logout Successfully!",
    login: "Welcome Back!",
    nodata: "Data not found",
    nodateData: "No ltinerary for the said date!",
  },
  errors: {
    user_req: "Username is required!",
    pass_req: "Password is required!",
    product_name_err: "Name is required!",
    quantity_err: "Quantity is required!",
    reason_err: "Reason is required!",
    product_req: "Please select any one product",
    answer_req: "Please answer any one question",
    sample_req: "Please edit qty any one product",
    behavior_req: "MD Behavior is required!",
    product_behav_req: "Please select any one product and Write MD Behavior",
    sample_behav_req: "Please edit qty any one product and Write MD Behavior",
    answer_behav_req: "Please answer any one question and Write MD Behavior",
    join_call_req: "Who joined this call is required!",
    sign_req: "Please Draw signature frist",
    post_call_req: "Post Call Analysis is required!",
    next_call_req: "Next Call Objective is required!",
    md_select_req: "Please select MD!",
    md_name_req: "Firstname and Lastname is required!",
    image_req: "Please select screenshot is required!",
  },
};
