import { messages } from "./messages";

export const formValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("username" in fieldValues) {
    temp.username = fieldValues.username ? "" : messages.errors.user_req;
  }
  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : messages.errors.pass_req;
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const returnPromoMatsValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("return_qty" in fieldValues) {
    temp.return_qty =
      fieldValues.return_qty > 0
        ? values.quantity < fieldValues.return_qty
          ? `Return quantity cannot be greater than ${values.quantity}!`
          : ""
        : messages.errors.quantity_err;
  }
  if ("reason" in fieldValues) {
    temp.reason = fieldValues.reason ? "" : messages.errors.reason_err;
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const adjustPromoMatsValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("received_qty" in fieldValues) {
    // temp.received_qty =
    //   fieldValues.received_qty > 0 ? "" : messages.errors.quantity_err;
    temp.received_qty =
      fieldValues.received_qty > 0
        ? values.issued_qty < fieldValues.received_qty
          ? `Acutal quantity cannot be greater than ${values.issued_qty}!`
          : ""
        : messages.errors.quantity_err;
  }
  if ("reason" in fieldValues) {
    temp.reason = fieldValues.reason ? "" : messages.errors.reason_err;
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const outOfFieldValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("oof_type_id" in fieldValues) {
    temp.oof_type_id = fieldValues.oof_type_id ? "" : "Please select a Type!";
  }
  if ("notes_id" in fieldValues) {
    temp.notes_id = fieldValues.notes_id ? "" : "Please select a Reason!";
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
