import React from "react";
import { Navigate } from "react-router-dom";
import { routes } from "./helpers/constants";

// Layouts
import MainLayout from "./components/Layout/MainLayout";
import AuthPage from "./components/Layout/AuthPage";

// Auth pages
import LoginPage from "./pages/Login/LoginPage";

// Comman pages
import Dashboard from "./pages/Dashboard/Dashboard";
import MdList from "./pages/MdList/MdList";
import QuickSign from "./pages/QuickSign/QuickSign";
import PromoMaterials from "./pages/PromoMaterials/PromoMaterials";
import OutOfField from "./pages/OutOfField/OutOfField";
import CallActivities from "./pages/CallRecording/CallActivities";
import ReceivePromoMats from "./pages/PromoMaterials/ReceivePromoMats";

const getRoutes = (user) => [
  {
    path: routes.homepage,
    element: user ? <AuthPage /> : <Navigate to={routes.mdList} />,
    children: [
      { path: routes.homepage, element: <Navigate to={routes.login} /> },
      { path: routes.login, element: <LoginPage /> },
    ],
  },
  {
    path: routes.homepage,
    element: !user ? <MainLayout /> : <Navigate to={routes.login} />,
    children: [
      { path: routes.mdList, element: <MdList /> },
      { path: routes.dashboard, element: <Dashboard /> },
      { path: routes.callActivities, element: <CallActivities /> },
      { path: routes.quickSign, element: <QuickSign /> },

      { path: routes.promoMaterials, element: <PromoMaterials /> },
      { path: routes.recivePromoMats, element: <ReceivePromoMats /> },

      { path: routes.outOfField, element: <OutOfField /> },
    ],
  },
];

export default getRoutes;
