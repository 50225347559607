import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import RecordOutField from "../../components/Common/Modals/RecordOutField";
import { useDispatch, useSelector } from "react-redux";
import {
  addOutOfFieldData,
  reqToGetModalData,
  reqToGetOutOfFieldData,
} from "../../store/slice/outOfFieldSlice";
import moment from "moment/moment";
import { useForm } from "../../hooks/useForm";
import Loader from "../../components/Common/Loader";

const OutOfField = (props) => {
  const dispatch = useDispatch();

  const outOfFieldList = useSelector(
    (state) => state.outOfFieldReducer.outOfFieldData
  );
  const modalData = useSelector((state) => state.outOfFieldReducer.modalData);
  const isLoading = useSelector((state) => state.outOfFieldReducer.loader);

  const [modalShow, setModalShow] = useState(false);
  const [updatedList, setUpdatedList] = useState({});
  const [dateCycle, setDateCycle] = useState({});
  const [typeList, setTypeList] = useState([]);
  const [reasonsList, setReasonsListList] = useState([]);

  useEffect(() => {
    dispatch(reqToGetOutOfFieldData());
    dispatch(reqToGetModalData());
  }, []);

  const divideArr = () => {
    let updatedOutOfFieldList = {
      allDayArr: [],
      morningArr: [],
      afternoonArr: [],
    };
    for (let i = 0; i < outOfFieldList?.length; i++) {
      if (outOfFieldList[i].type_id === 1) {
        updatedOutOfFieldList.allDayArr.push(outOfFieldList[i]);
      } else if (outOfFieldList[i].type_id === 2) {
        updatedOutOfFieldList.morningArr.push(outOfFieldList[i]);
      } else {
        updatedOutOfFieldList.afternoonArr.push(outOfFieldList[i]);
      }
    }
    return updatedOutOfFieldList;
  };

  useEffect(() => {
    setUpdatedList(divideArr());
  }, [outOfFieldList]);

  useEffect(() => {
    setDateCycle(modalData?.cycle);
    setTypeList(modalData?.oof_type);
    setReasonsListList(modalData?.notes);
  }, [modalData]);

  // Initial adjust form values
  const initialFValues = {
    date: "",
    oof_type_id: "",
    notes_id: "",
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );
  //

  useEffect(() => {
    setValues({
      ...values,
      date: moment(dateCycle?.start)._d,
      oof_type_id: typeList && typeList[0]?.id,
      notes_id: reasonsList && reasonsList[0]?.id,
    });
  }, [dateCycle, typeList, reasonsList]);

  const onAddRecordHandler = () => {
    const data = {
      date: values.date && moment(values.date).format().split("T")[0],
      oof_type_id: +values.oof_type_id,
      notes_id: +values.notes_id,
    };
    dispatch(
      addOutOfFieldData({
        data,
        cb: (err, red) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(reqToGetOutOfFieldData());
            setModalShow(false);
            setValues({
              ...values,
              date: moment(dateCycle?.start)._d,
              oof_type_id: "",
              notes_id: "",
            });
          }
        },
      })
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body-section">
        <div className="tc-promo-content tc-field-content">
          <h2 className="tc-page-title">Out-Of-Field</h2>
          <div className="tc-table-content">
            <h2 className="tc-table-title">All Day</h2>
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th>date</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                {updatedList?.allDayArr?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">
                        {moment(item.date).format("LL")}
                      </td>
                      <td className="text-start">
                        {item.reason}
                        <div
                          className="tc-sidebar-btn"
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        ></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="tc-table-content">
            <h2 className="tc-table-title">Morning</h2>
            <Table
              locale={{ emptyText: "No data" }}
              className="tc-table tc-daily-table"
            >
              <thead>
                <tr>
                  <th>date</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                {updatedList?.morningArr?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">
                        {" "}
                        {moment(item.date).format("LL")}
                      </td>
                      <td className="text-start">
                        {item.reason}
                        <div
                          className="tc-sidebar-btn"
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        ></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <div className="tc-table-content">
            <h2 className="tc-table-title">Afternoon</h2>
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th>date</th>
                  <th>reason</th>
                </tr>
              </thead>
              <tbody>
                {updatedList?.afternoonArr?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">
                        {" "}
                        {moment(item.date).format("LL")}
                      </td>
                      <td className="text-start">
                        {item.reason}
                        <div
                          className="tc-sidebar-btn"
                          variant="primary"
                          onClick={() => setModalShow(true)}
                        ></div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <button
            type="button"
            className="tc-save"
            onClick={() => setModalShow(true)}
          >
            ADD
          </button>

          <RecordOutField
            dateCycle={dateCycle}
            show={modalShow}
            typeList={typeList}
            reasonsList={reasonsList}
            values={values}
            setValues={setValues}
            errors={errors}
            onAddRecordHandler={onAddRecordHandler}
            handleInputChange={handleInputChange}
            onHide={() => setModalShow(false)}
          />
        </div>
      </div>
    </>
  );
};

export default OutOfField;
