import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReturnpromoMats from "../../components/Common/Modals/ReturnpromoMats";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToGetPromoMatList,
  reqToGetPromoMatsReasons,
  updateMaterial,
} from "../../store/slice/promoMaterialSlice";
import Loader from "../../components/Common/Loader";
import { returnPromoMatsValidations } from "../../helpers/formValidations";
import { useForm } from "../../hooks/useForm";

const PromoMaterials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const [materialObj, setMaterialObj] = useState({});
  const [updatedList, setUpdatedList] = useState({});

  const promoMaterialList = useSelector(
    (state) => state.promoMaterialReducer.promoMaterialsList
  );

  const isLoading = useSelector((state) => state.promoMaterialReducer.loader);

  useEffect(() => {
    setUpdatedList(divideArr());
  }, [promoMaterialList]);

  useEffect(() => {
    dispatch(reqToGetPromoMatList());
    dispatch(reqToGetPromoMatsReasons());
  }, []);

  // Initial values
  const initialFValues = {
    return_qty: "",
    reason: null,
  };

  useEffect(() => {
    setValues({
      ...values,
      return_qty: 0,
      quantity: materialObj && materialObj.quantity ? materialObj.quantity : "",
      received_qty:
        materialObj && materialObj.received_qty ? materialObj.received_qty : "",
    });
  }, [materialObj]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return returnPromoMatsValidations(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  //

  // save single Material
  const saveMatsHandler = () => {
    if (validate()) {
      const data = {
        promomat_id: +materialObj.promomat_id,
        qty: +values.return_qty,
        notes_id: +values.reason,
        received_qty: +materialObj.received_qty,
      };
      dispatch(
        updateMaterial({
          data,
          cb: (err, res) => {
            if (err) {
              console.log("err", err);
            } else {
              setModalShow(false);
              setErrors({});
              dispatch(reqToGetPromoMatList());
              setValues({
                return_qty: "",
                reason: "",
              });
            }
          },
        })
      );
    }
  };

  const divideArr = () => {
    let updatedPromoMaterials = {
      givewaysArr: [],
      samplesArr: [],
      literatureArr: [],
    };
    for (let i = 0; i < promoMaterialList?.length; i++) {
      if (promoMaterialList[i].product_type_id === 1) {
        updatedPromoMaterials.givewaysArr.push(promoMaterialList[i]);
      } else if (promoMaterialList[i].product_type_id === 2) {
        updatedPromoMaterials.samplesArr.push(promoMaterialList[i]);
      } else {
        updatedPromoMaterials.literatureArr.push(promoMaterialList[i]);
      }
    }
    return updatedPromoMaterials;
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body-section">
        <div className="tc-promo-content tc-promo-materials-content">
          <h2 className="tc-page-title">Promo Materials</h2>
          <div className="tc-table-content">
            <h2 className="tc-table-title">Giveways</h2>
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th>name</th>
                  <th>balance</th>
                </tr>
              </thead>
              {updatedList?.givewaysArr?.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={2}>No data found</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {updatedList?.givewaysArr?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start tc-md-list-table-heading">
                          {item.product_name}
                        </td>
                        <td>
                          {item.quantity}
                          <div
                            className="tc-sidebar-btn"
                            variant="primary"
                            onClick={() => {
                              setMaterialObj(item);
                              setModalShow(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
          <div className="tc-table-content">
            <h2 className="tc-table-title">Samples</h2>
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th>name</th>
                  <th>balance</th>
                </tr>
              </thead>
              {updatedList?.samplesArr?.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={2}>No data found</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {updatedList?.samplesArr?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start tc-md-list-table-heading">
                          {item.product_name}
                        </td>
                        <td>
                          {item.quantity}
                          <div
                            className="tc-sidebar-btn"
                            variant="primary"
                            onClick={() => {
                              setMaterialObj(item);
                              setModalShow(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
          <div className="tc-table-content">
            <h2 className="tc-table-title">Literature</h2>
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th>name</th>
                  <th>balance</th>
                </tr>
              </thead>
              {updatedList?.literatureArr?.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={2}>No data found</td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {updatedList?.literatureArr?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start tc-md-list-table-heading">
                          {item.product_name}
                        </td>
                        <td>
                          {item.quantity}
                          <div
                            className="tc-sidebar-btn"
                            variant="primary"
                            onClick={() => {
                              setMaterialObj(item);
                              setModalShow(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
          <button
            type="button"
            className="tc-save"
            onClick={() => navigate(`${routes.promoMaterials}/receive`)}
          >
            RECEIVE
          </button>
          <ReturnpromoMats
            show={modalShow}
            onHide={() => {
              setErrors({});
              setValues({
                ...values,
                return_qty: "",
                reason: "",
              });
              setMaterialObj({});
              setModalShow(false);
            }}
            values={values}
            errors={errors}
            saveMatsHandler={saveMatsHandler}
            handleInputChange={handleInputChange}
            materialObj={materialObj}
          />
        </div>
      </div>
    </>
  );
};

export default PromoMaterials;
