import { configureStore } from "@reduxjs/toolkit";

// Slice
import userSlice from "./slice/userSlice";
import mdListSlice from "./slice/mdListSlice";
import dashboardSlice from "./slice/dashboardSlice";
import outOfFieldSlice from "./slice/outOfFieldSlice";
import promoMaterialSlice from "./slice/promoMaterialSlice";
import quickSignSlice from "./slice/quickSignSlice";
import callRecordingSlice from "./slice/callRecordingSlice";

const store = configureStore({
  reducer: {
    userReducer: userSlice,
    mdListReducer: mdListSlice,
    dashboardReducer: dashboardSlice,
    callRecordingReducer: callRecordingSlice,
    outOfFieldReducer: outOfFieldSlice,
    promoMaterialReducer: promoMaterialSlice,
    quickSignReducer: quickSignSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
