import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const ReturnpromoMats = (props) => {
  const {
    materialObj,
    onHide,
    show,
    values,
    errors,
    handleInputChange,
    saveMatsHandler,
  } = props;

  const promoMatsReasons = useSelector(
    (state) => state.promoMaterialReducer.promoMatsReasons
  );

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Return Promo Materials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <div className="row mb-3">
              <p className="col-4">Name: </p>
              <div className="col-8">
                <Form.Control
                  type="text"
                  id="product_name"
                  name="product_name"
                  placeholder="Giveway1"
                  value={values.product_name}
                  onChange={handleInputChange}
                  disabled={true}
                />
                <span className="tc-error-text">
                  {errors.product_name || ""}
                </span>
              </div>
            </div> */}
            <div className="row mb-3">
              <p className="col-4">Name: </p>
              <div className="col-8">
                <p>
                  {materialObj && materialObj.product_name
                    ? materialObj.product_name
                    : ""}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              {/* <p className="col-4">Received Quantity: </p> */}
              <p className="col-4">Balance Quality: </p>
              <div className="col-8">
                <p>
                  {materialObj && materialObj.quantity
                    ? materialObj.quantity
                    : ""}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Return Quantity:</p>
              <div className="col-8">
                <Form.Control
                  type="text"
                  id="return_qty"
                  name="return_qty"
                  placeholder="Input quantity"
                  value={values.return_qty}
                  onChange={handleInputChange}
                />
                <span className="tc-error-text">{errors.return_qty || ""}</span>
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Reason:</p>
              <div className="col-8">
                <Form.Select
                  aria-label="Default select example"
                  name="reason"
                  id="reason"
                  onChange={(e) => handleInputChange(e, "reason")}
                >
                  <option disabled selected>
                    Select
                  </option>
                  {promoMatsReasons?.length === 0 ? (
                    <option disabled>No reasons found</option>
                  ) : (
                    promoMatsReasons?.map((reason, i) => {
                      return (
                        <option key={i} value={reason.id}>
                          {reason.name}
                        </option>
                      );
                    })
                  )}
                </Form.Select>
                <span className="tc-error-text">{errors.reason || ""}</span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button className="tc-save" onClick={() => saveMatsHandler()}>
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReturnpromoMats;
