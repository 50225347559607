export const routes = {
  all: "*",
  homepage: "/",
  login: "/login",
  dashboard: "/dashboard",
  mdList: "/mdList",
  callActivities: "/callActivities",
  quickSign: "/quickSign",
  quickList: "/quickList",
  promoMaterials: "/promo-meterials",
  recivePromoMats: "/promo-meterials/receive",
  outOfField: "/outOfField",
};

export const apiendpoints = {
  login: "/login",
  dashboard: "/dashboard",
  mdList: "/md/list",
  searchMD: "/md/search",
  activeCycle: "md/active-cycle",

  // Call recording (call activities)
  callActivities: "/call-recording/call-activities",
  productDetailing: "/call-recording/product-detailing",
  marketResearch: "/call-recording/market-research",
  productSamples: "/call-recording/product-sampling",
  jointCall: "/call-recording/joint-call",
  callSummary: "/call-recording/call-summary",
  media: "/media",
  mdSignature: "/call-recording/add-md-signature",
  addProductDetailing: "/call-recording/add-product-detailing",
  addMarketResearch: "/call-recording/add-market-research",
  addProductSampling: "/call-recording/add-product-sampling",
  addJointCall: "/call-recording/add-joint-call",

  // QuickSign
  quickSignCount: "/quick-sign/count-quick-sign",
  quickSignlist: "/quick-sign/list-quick-sign",
  quickSignmdList: "/quick-sign/md-list-quick-sign",
  addQuickSign: "/quick-sign/add-quick-sign",
  addMD: "/quick-sign/add-md",
  quickSignUpdate: "/quick-sign/quick-sign-update",

  quickSign: "/quickSign",

  promoMaterialList: "/promomat/material-balance-list",
  promoMatsReasons: "/promomat/reject-reasons",
  updateMaterial: "/promomat/return-material",
  reciveMatsList: "/promomat/material-issued-list",
  adjustReasons: "/promomat/adjust-reasons",
  submitReceiveMats: "/promomat/receive-materials",

  outOfField: "/out-of-field/list-oof-data",
  outOfFieldModalData: "/out-of-field/get-model-data",
  addOutOfField: "/out-of-field/add-oof-data",
};
