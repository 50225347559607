import React, { useEffect, useRef, useState } from "react";
import { backBtnIcon } from "../../icon/icon";
import { Table } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import {
  mdDetailsObjHandler,
  reqToAddMediaFile,
} from "../../store/slice/callRecordingSlice";
import { messages } from "../../helpers/messages";
import moment from "moment";

const CallSummary = (props) => {
  const {
    method,
    showCallSummary,
    handleShowPostCall,
    quicksigndata,
    handleShowCallSummary,
  } = props;
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const callActivitieData =
    callRecordingReducer && callRecordingReducer.callActivitieData;
  const productDObj = callRecordingReducer && callRecordingReducer.productDObj;
  const marketRObj = callRecordingReducer && callRecordingReducer.marketRObj;
  const productSObj = callRecordingReducer && callRecordingReducer.productSObj;
  const jointCObj = callRecordingReducer && callRecordingReducer.jointCObj;

  // States
  const signPadRef = useRef();
  const [fileUrl, setFileUrl] = useState();
  const [fileData, setFileData] = useState("");
  const [signData, setSignData] = useState("");
  const [errors, setErrors] = useState({
    mdSign: "",
  });

  const clear = () => {
    if (fileData && fileData) {
      setFileUrl("");
      setFileData("");
    } else {
      signPadRef.current.clear();
      setSignData("");
    }
  };

  useEffect(() => {
    if (showCallSummary === true) {
      setErrors({
        ...errors,
        mdSign: "",
      });
    }
  }, [showCallSummary]);

  // Check validation
  const checkValidation = () => {
    if (method === "" && !signData) {
      setErrors({
        ...errors,
        mdSign: messages.errors.sign_req,
      });
      return false;
    } else if (method && !fileData) {
      setErrors({
        ...errors,
        mdSign: messages.errors.image_req,
      });
    } else {
      setErrors({
        ...errors,
        mdSign: "",
      });
      return true;
    }
  };

  // Handle onchange draw signature
  const handleSignEnd = () => {
    if (!signPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        mdSign: "",
      });
    }
    setSignData(signPadRef.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  // Handle select images
  const handleSelectImage = (event) => {
    const { files } = event.target;
    const imageUrl = URL.createObjectURL(files && files[0]);
    setFileUrl(imageUrl);
    setFileData(files && files[0]);
    setErrors({
      ...errors,
      mdSign: "",
    });
  };

  // Handle md signature
  const handleUploadMediaFiles = () => {
    if (!quicksigndata?.signature_url) {
      if (checkValidation()) {
        if (fileData && fileData) {
          const formData = new FormData();
          formData.append("file", fileData && fileData);
          handleFileUpload(formData);
        } else {
          let signDataCanvas = signPadRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png");
          const blobData = dataURLtoBlob(signDataCanvas);
          const formData = new window.FormData();
          formData.append("file", blobData, "signature.png");
          handleFileUpload(formData);
        }
      }
    } else {
      handleShowPostCall();
    }
  };

  // File upload
  const handleFileUpload = (formData) => {
    dispatch(
      reqToAddMediaFile({
        data: formData,
        onSuccess: (response) => {
          if (response && response?.data?.length > 0) {
            let imagesUrl =
              response && response?.data[0]?.imageUrlBigUrl.split("/");
            let imageName = imagesUrl && imagesUrl[imagesUrl?.length - 1];
            const tempObj = {
              id: "",
              name: "",
              signature_url: imageName,
            };
            dispatch(mdDetailsObjHandler(tempObj));
            handleShowPostCall();
          }
        },
      })
    );
  };

  return (
    <>
      <div
        className={`tc-sidebar-section ${showCallSummary && "zl-sidebar-open"}`}
      >
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleShowCallSummary()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Call Summary</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {callActivitieData &&
                  callActivitieData?.firstname +
                    " " +
                    callActivitieData?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData && callActivitieData?.specialty_name
                  ? callActivitieData?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData && callActivitieData?.location_name
                  ? callActivitieData?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData && callActivitieData?.target
                  ? handleVisits(
                      callActivitieData?.actual_visits,
                      callActivitieData?.target
                    )
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Objective:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData &&
                  callActivitieData?.objective &&
                  callActivitieData?.objective}
              </p>
            </div>
          </div>
          <Table className="tc-table tc-join-call-table">
            <thead>
              <tr>
                <th colSpan="2">ACTIVITIES DONE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tc-md-list-table-heading">Product Detailing</td>
                <td>
                  {productDObj &&
                    productDObj?.length > 0 &&
                    productDObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item.name}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Market Research</td>
                <td>
                  {marketRObj &&
                    marketRObj?.length > 0 &&
                    marketRObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item?.question} {item?.name}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Product Samples</td>
                <td>
                  {productSObj &&
                    productSObj?.length > 0 &&
                    productSObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item?.name}: {item?.quantity}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Joint Call</td>
                <td>
                  <p className="tc-md-list-table-paragraph">
                    {`${
                      jointCObj && jointCObj?.name
                        ? "with" + " " + jointCObj?.name
                        : ""
                    }`}
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="tc-md-list-behavior-input-box">
            <label className="tc-md-list-behavior-input-label">
              {quicksigndata && quicksigndata?.signature_url
                ? "Screenshot:"
                : !method
                ? "MD Signature:"
                : "Screenshot:"}
            </label>
            {quicksigndata && quicksigndata?.signature_url ? (
              <>
                <div className="tc-quicklist-canvas">
                  {quicksigndata.signature_url && (
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        quicksigndata.signature_url
                      }
                      alt="Signature"
                      draggable={false}
                    />
                  )}
                </div>
              </>
            ) : method === "" ? (
              <div className="tc-call-summary-signature-box edhedde">
                <button
                  className="tc-call-summary-signature-erase-btn"
                  onClick={clear}
                >
                  Erase
                </button>
                <SignaturePad
                  canvasProps={{
                    className: "tc-join-call-digi-signaturepad",
                  }}
                  ref={signPadRef}
                  onEnd={() => handleSignEnd()}
                />
              </div>
            ) : (
              <div className="tc-call-summary-signature-box tc-call-summary-photo-upload-box tc-call-summary-photo-upload-img-box">
                <button
                  className="tc-call-summary-signature-erase-btn"
                  onClick={clear}
                >
                  Erase
                </button>
                <label
                  htmlFor="tc-call-summary-photo-upload-input"
                  className="tc-call-summary-photo-upload-box-text"
                >
                  <span>{!fileData && "Tap to upload screenshot"}</span>
                </label>
                <input
                  type="file"
                  className="d-none"
                  id="tc-call-summary-photo-upload-input"
                  value=""
                  onChange={(e) => handleSelectImage(e)}
                />
                {fileData && (
                  <img
                    className="tc-call-summary-photo-upload-img"
                    src={fileUrl && fileUrl}
                    alt="img"
                  />
                )}
              </div>
            )}
            {errors && errors?.mdSign && (
              <span className="tc-error">{errors && errors?.mdSign}</span>
            )}
            <p className="tc-signaturepad-time-text">
              {moment().format("lll")}
            </p>
          </div>
          <div className="tc-md-list-detail-bottom-btn-content">
            <button
              type="button"
              className="tc-md-list-detail-bottom-btn"
              onClick={() => handleUploadMediaFiles()}
            >
              next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallSummary;
