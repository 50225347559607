import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to get dashboard data
export const reqToGetDashboardData = createAsyncThunk(
  "reqToGetDashboardData",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.dashboard,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  dashboardData: [],
};

// Create dashboard slice
const dashboardSlice = createSlice({
  name: "mdList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get dashboard Data
    builder.addCase(reqToGetDashboardData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDashboardData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dashboardData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDashboardData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
