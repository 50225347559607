import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Webcam from "react-webcam";

const WebcamCapture = (props) => {
  const { webcamref, captureimage } = props;

  // function success(stream) {
  //   // The success function receives an argument which points to the webcam stream
  //   document.getElementById("myVideo").src = stream;
  // }

  // function error() {
  //   alert("No webcam for you, matey!");
  // }

  // if (navigator.getUserMedia) {
  //   navigator.getUserMedia({ video: true, audio: false }, success, error);
  // } else {
  //   error();
  // }

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Screenshot
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Webcam
            className="tc-modal-click-photo"
            ref={webcamref}
            screenshotFormat="image/png"
          />
          <div className="text-center">
            <Button
              className="tc-save"
              type="submit"
              onClick={(e) => captureimage(e)}
            >
              Capture
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WebcamCapture;
