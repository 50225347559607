import React, { useEffect, useState } from "react";
import { callSummaryIcon, rightCircleShortFillIcon } from "../../icon/icon";
import { Link } from "react-router-dom";
import Axios, { authHeaders } from "../../helpers/reportaxios";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { useTable } from "react-table";
import Loading from "./Loading";

const RptIdCallDeviationSummary = 1;
const RptIdCallConcentration = 2;
const RptIdCallSummaryCTD = 3;
const RptIdDaysInField = 4;
const RptIdMDCoveragePerDay = 5;
const RptIdMDCoveragePerWeek = 6;
const RptIdPromomatAllocation = 7;
const RptIdPromomatAllocationPerMD = 8;

const Dashboard = () => {
  const [summaryTable, setSummaryTable] = useState([]);
  const [rptData, setRptData] = useState([]);
  // const summaryTable = [
  //   {
  //     id: 1,
  //     class: 'a',
  //     actualClass: '0',
  //     targetCalls: '112',
  //     classPercentage: '0.00%',
  //     actualCount: '0',
  //     targetCount: '28',
  //     countPercentage: '0.00%',
  //     aveFreq: '0.0',
  //     targetFreq: '4.0',
  //   },
  //   {
  //     id: 2,
  //     class: 'b',
  //     actualClass: '0',
  //     targetCalls: '0',
  //     classPercentage: '0.00%',
  //     actualCount: '0',
  //     targetCount: '0',
  //     countPercentage: '0.00%',
  //     aveFreq: '0.0',
  //     targetFreq: '0.0',
  //   },
  //   {
  //     id: 3,
  //     class: 'c',
  //     actualClass: '0',
  //     targetCalls: '204',
  //     classPercentage: '0.00%',
  //     actualCount: '0',
  //     targetCount: '102',
  //     countPercentage: '0.00%',
  //     aveFreq: '0.0',
  //     targetFreq: '2.0',
  //   },
  //   {
  //     id: 4,
  //     class: 'b',
  //     actualClass: '0',
  //     targetCalls: '0',
  //     classPercentage: '0.00%',
  //     actualCount: '0',
  //     targetCount: '0',
  //     countPercentage: '0.00%',
  //     aveFreq: '0.0',
  //     targetFreq: '0.0',
  //   },
  //   {
  //     id: 5,
  //     class: 'x',
  //     actualClass: '0',
  //     targetCalls: '0',
  //     classPercentage: '0.00%',
  //     actualCount: '0',
  //     targetCount: '0',
  //     countPercentage: '0.00%',
  //     aveFreq: '0.0',
  //     targetFreq: '0.0',
  //   },
  // ];

  const messageBoardData = [
    {
      id: 1,
      messageBoardDate: "May 22",
      messageBoardHead: "District Meeting",
      messageBoardParagraph: "from John Doe Cruz",
    },
    {
      id: 2,
      messageBoardDate: "May 19",
      messageBoardHead: "Training Rescheduled",
      messageBoardParagraph: "from John Doe Cruz",
    },
    {
      id: 3,
      messageBoardDate: "May 16",
      messageBoardHead: "New Samples",
      messageBoardParagraph: "from John Doe Cruz",
    },
  ];

  const reportData = [
    {
      id: 1,
      reportText: "Call Concentration",
    },
    {
      id: 2,
      reportText: "Call Summary CTD",
    },
    {
      id: 3,
      reportText: "MD Coverage",
    },
    {
      id: 4,
      reportText: "Call Deviation Summary",
    },
    {
      id: 5,
      reportText: "Days in Field",
    },
    {
      id: 6,
      reportText: "Promo Materials Allocation",
    },
  ];

  const generateCellStyles = (align, value, type, header) => {
    let styleObject = {
      color: "currentColor",
      // the cells we actually don't want to be flex
      textAlign: "",
    };

    styleObject.textAlign = align;

    // // color ACOS data
    // if (header === "ACOS") {
    //   styleObject.color =
    //     value <= 0.2
    //       ? "var(--green-dark)"
    //       : value <= 0.4
    //       ? "var(--black)"
    //       : value <= 0.5
    //       ? "var(--orange-dark)"
    //       : "var(--red-dark)";
    // }

    return {
      style: styleObject,
    };
  };

  const combinedCellProps = (defaultCellProps, { cell }) => {
    return [
      defaultCellProps,
      generateCellStyles(
        cell.column.align,
        cell.value,
        cell.column.type,
        cell.column.Header
      ),
    ];
  };

  const formatPercentage = (value) => {
    if (value) {
      return `${value.toFixed(2)}%`;
    } else {
      return "0.00%";
    }
  };

  const formatDecimal = (value, decimal) => {
    if (value) {
      return `${value.toFixed(decimal)}`;
    } else {
      return "-";
    }
  };

  const formatDate = (value) => {
    if (value) {
      return moment(value).format("YYYY-MM-DD h:mma");
    } else {
      return "";
    }
  };

  const getTotals = (data, key) => {
    let total = 0;
    data.forEach((item) => {
      total += item[key];
    });
    return total;
  };

  const reports = [
    {
      id: RptIdCallDeviationSummary,
      title: "Call Deviation Summary",
      columns: () => [
        {
          Header: "Class",
          accessor: "class",
        },
        {
          Header: "On-Time",
          accessor: "regular",
        },
        {
          Header: "Make-Up",
          accessor: "makeup",
        },
        {
          Header: "Advanced",
          accessor: "advanced",
        },
        {
          Header: "Extra",
          accessor: "extra",
        },
      ],
      endpoint: "calldeviationsummary",
    },
    {
      id: RptIdCallConcentration,
      title: "Call Concentration",
      columns: () => [
        {
          Header: "Class",
          accessor: "class",
        },
        {
          Header: "0x",
          accessor: "md_0",
        },
        {
          Header: "1x",
          accessor: "md_1",
        },
        {
          Header: "2x",
          accessor: "md_2",
        },
        {
          Header: "3x",
          accessor: "md_3",
        },
        {
          Header: "4x",
          accessor: "md_4",
        },
        {
          Header: ">4x",
          accessor: "md_gt4",
        },
        {
          Header: "Total",
          accessor: "target_md",
        },
      ],
      endpoint: "callconcentration",
    },
    {
      id: RptIdCallSummaryCTD,
      title: "Call Summary CTD",
      columns: () => [
        {
          Header: "Class",
          accessor: "class",
        },
        {
          Header: "Actual MD",
          accessor: "actual_md",
        },
        {
          Header: "Target MD",
          accessor: "target_md",
        },
        {
          Header: "% MD",
          accessor: "perf_md",
          Cell: ({ value }) => formatPercentage(value),
        },
        {
          Header: "F2F Call",
          accessor: "f2f_call",
        },
        {
          Header: "Virtual Call",
          accessor: "virtual_call",
        },
        {
          Header: "Actual Call",
          accessor: "actual_call",
        },
        {
          Header: "Target Call",
          accessor: "target_call",
        },
        {
          Header: "% Call",
          accessor: "perf_call",
          Cell: ({ value }) => formatPercentage(value),
        },
      ],
      endpoint: "callsummaryctd",
    },
    {
      id: RptIdDaysInField,
      title: "Days in Field",
      columns: () => [
        {
          Header: "Description",
          accessor: "name",
        },
        {
          Header: "Days",
          accessor: "total",
          Cell: ({ value }) => formatDecimal(value, 1),
        },
      ],
      endpoint: "daysinfield",
    },
    {
      id: RptIdMDCoveragePerDay,
      title: "MD Coverage per Day",
      columns: () => [
        {
          Header: "Date and Time",
          accessor: "record_time",
          Cell: ({ value }) => formatDate(value),
        },
        {
          Header: "Lastname",
          accessor: "lastname",
        },
        {
          Header: "Firstname",
          accessor: "firstname",
        },
        {
          Header: "Class",
          accessor: "class",
        },
        {
          Header: "Specialty",
          accessor: "specialty",
        },
        {
          Header: "Location",
          accessor: "location",
        },
      ],
      endpoint: "mdcoverageperday",
    },
    {
      id: RptIdMDCoveragePerWeek,
      title: "MD Coverage per Week",
      columns: () => [
        {
          Header: "Week",
          accessor: "week_num",
        },
        {
          Header: "Date and Time",
          accessor: "record_time",
          Cell: ({ value }) => formatDate(value),
        },
        {
          Header: "Lastname",
          accessor: "lastname",
        },
        {
          Header: "Firstname",
          accessor: "firstname",
        },
        {
          Header: "Class",
          accessor: "class",
        },
        {
          Header: "Specialty",
          accessor: "specialty",
        },
        {
          Header: "Location",
          accessor: "location",
        },
      ],
      endpoint: "mdcoverageperweek",
    },
    {
      id: RptIdPromomatAllocation,
      title: "Promo Mat Allocation",
      columns: () => [
        {
          Header: "Promo Material",
          accessor: "name",
        },
        {
          Header: "Lastname",
          accessor: "lastname",
        },
        {
          Header: "Firstname",
          accessor: "firstname",
        },
        {
          Header: "Quantity",
          accessor: "quantity",
        },
      ],
      endpoint: "promomatallocation",
    },
    {
      id: RptIdPromomatAllocationPerMD,
      title: "Promo Mat Allocation per MD",
      columns: () => [
        {
          Header: "Lastname",
          accessor: "lastname",
        },
        {
          Header: "Firstname",
          accessor: "firstname",
        },
        {
          Header: "Promo Material",
          accessor: "name",
        },
        {
          Header: "Quantity",
          accessor: "quantity",
        },
      ],
      endpoint: "promomatallocationpermd",
    },
  ];

  const getReport = async () => {
    try {
      const response = await Axios.get(
        `/api/v1/reports/callsummaryctd?cycleId=1&territoryId=1`,
        null,
        authHeaders()
      );
      setRptData(response.data);
      setSummaryTable(
        response.data.map((i, index) => {
          return {
            class: i.class,
            actualClass: i.actual_call,
            targetCalls: i.target_call,
            classPercentage: formatPercentage(i.perf_call),
            actualCount: i.actual_md,
            targetCount: i.target_md,
            countPercentage: formatPercentage(i.perf_md),
            id: index,
          };
        })
      );
      // const actualMD = getTotals(data, "actual_md");
      // const targetMD = getTotals(data, "target_md");
      // const perfMD = targetMD == 0 ? 0 : actualMD * 100 / targetMD;
      // const f2fCall = getTotals(data, "f2f_call");
      // const virtualCall = getTotals(data, "virtual_call");
      // const actualCall = getTotals(data, "actual_call");
      // const targetCall = getTotals(data, "target_call");
      // const perfCall = targetCall == 0 ? 0 : actualCall * 100 / targetCall;
    } catch (e) {
      console.log(`error: ${e}`);
    }
  };

  useEffect(() => {
    getReport();
  }, []);

  const [reportId, setReportId] = useState(0);
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [columns, setColumns] = useState(() => []);

  const tableInstance = useTable({ columns, data: items });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const getApiData = async (reportId, endpoint) => {
    try {
      const response = await Axios.get(
        `/api/v1/reports/${endpoint}?cycleId=1&territoryId=1`,
        null,
        authHeaders()
      );
      console.log(`reponse=${JSON.stringify(response)}`);
      // update the state
      const data = response.data;
      switch (reportId) {
        case RptIdCallDeviationSummary:
          data.push({
            class: "TOTALS:",
            regular: getTotals(data, "regular"),
            makeup: getTotals(data, "makeup"),
            advanced: getTotals(data, "advanced"),
            extra: getTotals(data, "extra"),
            incidental: getTotals(data, "incidental"),
          });
          break;
        case RptIdCallConcentration:
          data.push({
            class: "TOTALS:",
            md_0: getTotals(data, "md_0"),
            md_1: getTotals(data, "md_1"),
            md_2: getTotals(data, "md_2"),
            md_3: getTotals(data, "md_3"),
            md_4: getTotals(data, "md_4"),
            md_gt4: getTotals(data, "md_gt4"),
            target_md: getTotals(data, "target_md"),
          });
          break;
        case RptIdCallSummaryCTD:
          const actualMD = getTotals(data, "actual_md");
          const targetMD = getTotals(data, "target_md");
          const perfMD = targetMD == 0 ? 0 : (actualMD * 100) / targetMD;
          const f2fCall = getTotals(data, "f2f_call");
          const virtualCall = getTotals(data, "virtual_call");
          const actualCall = getTotals(data, "actual_call");
          const targetCall = getTotals(data, "target_call");
          const perfCall =
            targetCall == 0 ? 0 : (actualCall * 100) / targetCall;
          data.push({
            class: "TOTALS:",
            actual_md: actualMD,
            target_md: targetMD,
            perf_md: perfMD,
            f2f_call: f2fCall,
            virtual_call: virtualCall,
            actual_call: actualCall,
            target_call: targetCall,
            perf_call: perfCall,
          });
          break;
        case RptIdDaysInField:
          data.push({
            name: "TOTAL:",
            total: getTotals(data, "total"),
          });
          break;
        default:
          data.push({});
          break;
      }
      setItems(data);
    } catch (e) {
      console.log(`error: ${e}`);
    }
  };

  return (
    <div className="tc-dashboard-body">
      <div className="tc-dashboard-table-box">
        <div className="tc-dashboard-table-box-heading">
          <h2 className="tc-dashboard-table-box-heading-text">
            {callSummaryIcon}
            Call Summary MTD <span>(2023 - CYCLE 01)</span>
          </h2>
          <p className="tc-dashboard-table-box-heading-date">Mon, May 22</p>
        </div>
        <div className="tc-dashboard-summary-table">
          <table>
            <thead>
              <tr className="tc-dashboard-summary-table-head">
                <th></th>
                <th colSpan={3}>Calls Frequency</th>
                <th colSpan={3}>Doctor Reach</th>
                {/* <th colSpan={2}>Ave. Frequency</th> */}
              </tr>
            </thead>
            <tbody>
              <tr className="tc-dashboard-summary-table-sec-head">
                <td>Class</td>
                <td>Actual Calls</td>
                <td>Target Calls</td>
                <td>%</td>
                <td>Actual Count</td>
                <td>Target Count</td>
                <td>%</td>
                {/* <td>Ave. Freq</td>
                <td>Target Freq</td> */}
              </tr>
              {summaryTable.map((item, i) => {
                return (
                  <tr className="tc-dashboard-summary-table-body-text">
                    <td>{item.class}</td>
                    <td>{item.actualClass}</td>
                    <td>{item.targetCalls}</td>
                    <td>{item.classPercentage}</td>
                    <td>{item.actualCount}</td>
                    <td>{item.targetCount}</td>
                    <td>{item.countPercentage}</td>
                    {/* <td>{item.aveFreq}</td>
                    <td>{item.targetFreq}</td> */}
                  </tr>
                );
              })}
              <tr className="tc-dashboard-summary-table-footer">
                <td>Total</td>
                <td>{getTotals(rptData, "actual_call")}</td>
                <td>{getTotals(rptData, "target_call")}</td>
                <td>
                  {getTotals(rptData, "target_call") == 0
                    ? "0.00%"
                    : formatPercentage(
                        (getTotals(rptData, "actual_call") * 100) /
                          getTotals(rptData, "target_call")
                      )}
                </td>
                <td>{getTotals(rptData, "actual_md")}</td>
                <td>{getTotals(rptData, "target_md")}</td>
                <td>
                  {getTotals(rptData, "target_md") == 0
                    ? "0.00%"
                    : formatPercentage(
                        (getTotals(rptData, "actual_md") * 100) /
                          getTotals(rptData, "target_md")
                      )}
                </td>
                {/* <td>0.0</td>
                <td>1.2</td> */}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="tc-dashboard-msgbord-report-content">
        <div className="row">
          <div className="col-sm-7">
            <div className="tc-dashboard-msgbord-col">
              <div className="tc-dashboard-msgbord-col-head">
                <h3>Message Board</h3>
                <Link
                  to={"/dashboard"}
                  className="tc-dashboard-msgbord-col-head-btn"
                >
                  {rightCircleShortFillIcon}
                </Link>
              </div>
              <div className="tc-dashboard-msgbord-col-list">
                {messageBoardData.map((item, i) => {
                  return (
                    <div className="tc-dashboard-msgbord-col-item">
                      <span className="tc-dashboard-msgbord-col-date">
                        {item.messageBoardDate}
                      </span>
                      <div className="tc-dashboard-msgbord-col-item-right-text">
                        <h4>{item.messageBoardHead}</h4>
                        <p>{item.messageBoardParagraph}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="tc-dashboard-report-col">
              <div className="tc-dashboard-report-col-head">
                <h3>Report</h3>
              </div>
              <div className="tc-dashboard-report-col-list">
                {/* {reportData.map((item, i) => {
                  return (
                    <p>{item.reportText}</p>
                  )
                })} */}
                {reports.map((rpt, i) => {
                  return (
                    // <div onClick={async () => {
                    //   setTitle(rpt.title);
                    //   setColumns(rpt.columns);
                    //   setReportId(rpt.id);
                    //   getApiData(rpt.id, rpt.endpoint);
                    //   setIsOpen(true);
                    // }}>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        setTitle(rpt.title);
                        setColumns(rpt.columns);
                        setReportId(rpt.id);
                        getApiData(rpt.id, rpt.endpoint);
                        setIsOpen(true);
                      }}
                    >
                      {rpt.title}
                    </p>
                    // </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={isOpen} size="xl" onHide={() => setIsOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%", textAlign: "center" }}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="scrollableDiv" style={{ height: "80vh", overflow: "auto" }}>
            <p style={{ fontWeight: "bold", height: "15px" }}>
              MR Name: Adrian Santos
            </p>
            <p style={{ fontWeight: "bold", height: "15px" }}>
              Territory: Terr01
            </p>
            <p style={{ fontWeight: "bold", marginBottom: "25px" }}>
              Cycle: July 2023
            </p>
            <InfiniteScroll
              dataLength={items.length}
              // next={fetchMoreData}
              hasMore={false}
              loader={<Loading />}
              scrollableTarget="scrollableDiv"
            >
              <table {...getTableProps()} className="table table-striped">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps()}
                          style={{ textAlign: "center" }}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (reportId == RptIdMDCoveragePerDay ||
                            reportId == RptIdMDCoveragePerWeek ||
                            reportId == RptIdPromomatAllocation ||
                            reportId == RptIdPromomatAllocationPerMD) &&
                            cell.value == null ? null : (
                            <td
                              {...cell.getCellProps(combinedCellProps)}
                              style={{
                                textAlign: "center",
                                fontWeight:
                                  row.index == rows.length - 1
                                    ? "bold"
                                    : "normal",
                                color:
                                  row.index == rows.length - 1
                                    ? "blue"
                                    : "black",
                              }}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dashboard;
