import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import { useForm } from "../../hooks/useForm";
import { formValidations } from "../../helpers/formValidations";
import { reqToUserLogin } from "../../store/slice/userSlice";

const LoginPage = () => {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);

  // Initial login form values
  const initialFValues = {
    username: "",
    password: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return formValidations(fieldValues, temp, values, setValues, setErrors);
  };

  // Custom form hooks
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  // Handle user login with username and password
  const handleUserLogin = (event) => {
    event.preventDefault();
    if (validate()) {
      dispatch(reqToUserLogin({ data: values }));
    }
  };

  return (
    <>
      {userReducer.loader && <Loader />}
      <div className="tc-login-section">
        <div className="tc-login-content">
          <Form className="tc-login-form">
            <a href="/" className="tc-logo">
              ETMS
            </a>
            <Form.Group className="tc-login-form-list">
              <div className="tc-login-form-list-input-row">
                <Form.Control
                  className="tc-login-option"
                  type="text"
                  name="username"
                  value={values.username}
                  onChange={handleInputChange}
                  placeholder="Username"
                />
              </div>
              <span className="tc-error-text">{errors && errors.username}</span>
            </Form.Group>
            <Form.Group className="tc-login-form-list">
              <div className="tc-login-form-list-input-row">
                <Form.Control
                  className="tc-login-option"
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  placeholder="Password"
                />
              </div>
              <span className="tc-error-text">{errors && errors.password}</span>
            </Form.Group>
            <button
              className="tc-save login-button"
              type="submit"
              onClick={(e) => handleUserLogin(e)}
            >
              Log in
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
