import React, { useEffect, useState } from "react";
import { PDFIcon, backBtnIcon } from "../../icon/icon";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import DataNotFound from "../../components/Common/DataNotFound";
import {
  productDObjHandler,
  updateMDBehavior,
} from "../../store/slice/callRecordingSlice";
import { messages } from "../../helpers/messages";

const ProductDetailing = (props) => {
  const { showsidebar, handleCallActivitiesSidebar, updateCallActivitieData } =
    props;
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const productDetailingData =
    callRecordingReducer && callRecordingReducer.productDetailingData;
  const activities = callRecordingReducer && callRecordingReducer.activities;
  const productDObj = callRecordingReducer && callRecordingReducer.productDObj;
  const mdBehavior = callRecordingReducer && callRecordingReducer.mdBehavior;

  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  // States
  const [errors, setErrors] = useState("");
  const [behavior, setBehavior] = useState(mdBehavior);
  const [productDetailing, setProductDetailing] = useState([]);
  const [productDetailingObj, setProductDetailingObj] = useState([]);

  // Customize productDetailing data array
  useEffect(() => {
    if (
      productDetailingData &&
      productDetailingData?.product?.length > 0 &&
      productDObj?.length === 0
    ) {
      const newDetailingData = productDetailingData.product.map((item) => {
        return item;
      });
      setProductDetailing(newDetailingData);
      setErrors("");
    }
    setBehavior(mdBehavior && mdBehavior ? mdBehavior : "");
  }, [callRecordingReducer]);

  // Update call activitie data and Close page
  useEffect(() => {
    if (productDObj && productDObj.length > 0) {
      handleCallActivitiesSidebar();
      updateCallActivitieData(activities.productD);
    }
  }, [productDObj]);

  // Check validation
  const checkValidation = () => {
    if (
      productDetailingObj &&
      productDetailingObj?.length === 0 &&
      behavior === ""
    ) {
      setErrors(messages.errors.product_behav_req);
      return false;
    } else if (productDetailingObj && productDetailingObj.length === 0) {
      setErrors(messages.errors.product_req);
      return false;
    } else if (behavior === "") {
      setErrors(messages.errors.behavior_req);
      return false;
    } else {
      setErrors("");
      return true;
    }
  };

  // Handle MD behavior
  const handleChangeMDBehavior = (event) => {
    setBehavior(event.target.value);
  };

  // Handle product ranks
  const handleProductRanks = (data) => {
    let newData = [...productDetailingObj];
    let filterData =
      productDetailingObj &&
      productDetailingObj?.find(
        (product) => product.product_id === data.product_id
      );
    if (!filterData) {
      let getLength = productDetailingObj && productDetailingObj.length;
      if (newData && newData?.length > 0) {
        newData[0] = {
          product_id: newData[0]?.product_id,
          name: newData[0]?.name,
          rank: newData[0]?.rank,
        };
      }
      newData.push({
        product_id: data.product_id,
        name: data.name,
        rank: getLength + 1,
      });
      setProductDetailing((prev) =>
        prev.map((item) => {
          if (item.product_id === data.product_id) {
            return {
              ...item,
              rank: getLength + 1,
            };
          } else {
            return item;
          }
        })
      );
      setProductDetailingObj(newData);
    }
    setModalShow(false);
  };

  // Update productDetailing data object
  const handleProductDetailingDataObj = () => {
    if (checkValidation()) {
      if (
        productDetailingObj &&
        productDetailingObj?.length !== productDObj?.length
      ) {
        dispatch(productDObjHandler(productDetailingObj));
        dispatch(updateMDBehavior(behavior));
      } else if (mdBehavior && mdBehavior !== behavior) {
        dispatch(updateMDBehavior(behavior));
        handleCallActivitiesSidebar();
      } else {
        handleCallActivitiesSidebar();
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showsidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Product Detailing</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {productDetailingData &&
                  productDetailingData?.firstname +
                    " " +
                    productDetailingData?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {productDetailingData && productDetailingData?.specialty_name
                  ? productDetailingData?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {productDetailingData && productDetailingData?.location_name
                  ? productDetailingData?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {productDetailingData && productDetailingData?.target
                  ? handleVisits(
                      productDetailingData?.actual_visits,
                      productDetailingData?.target
                    )
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Objective:</span>
              <p className="tc-md-list-common-detail-text">
                {productDetailingData &&
                  productDetailingData?.objective &&
                  productDetailingData?.objective}
              </p>
            </div>
          </div>
          <Table className="tc-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>View</th>
                <th>Rank</th>
              </tr>
            </thead>
            <tbody>
              {productDetailingData &&
              productDetailingData?.product?.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                <>
                  {productDetailing &&
                    productDetailing?.length > 0 &&
                    productDetailing.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="tc-md-list-table-heading">
                            {item?.name}
                          </td>
                          <td>
                            <button
                              type="button"
                              className="tc-md-list-table-pdf-btn"
                              onClick={() => {
                                setModalShow(true);
                                setSelectedProduct(item);
                              }}
                            >
                              {PDFIcon}
                            </button>
                          </td>
                          <td>
                            <p className="tc-md-list-table-pdf-open-number-text">
                              {item?.rank ? item?.rank : "N/A"}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
          {productDetailing && productDetailing?.length > 0 && (
            <>
              <div className="tc-md-list-behavior-input-box">
                <label className="tc-md-list-behavior-input-label">
                  MD Behavior:
                </label>
                <textarea
                  className="tc-md-list-behavior-input"
                  value={behavior}
                  onChange={(e) => handleChangeMDBehavior(e)}
                  spellCheck="false"
                />
                <span className="tc-error">{errors && errors}</span>
              </div>
              <div className="tc-md-list-detail-bottom-btn-content">
                <button
                  type="button"
                  className="tc-md-list-detail-bottom-btn"
                  onClick={() => handleProductDetailingDataObj()}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        className="tc-product-pdf-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedProduct && selectedProduct?.name} PDF
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && selectedProduct?.pdf_url ? (
            <iframe
              src={selectedProduct && selectedProduct?.pdf_url}
              className="tc-product-pdf-modal-img"
              title="product"
            />
          ) : (
            <div className="tc-product-pdf-not-found">File not found</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="tc-product-pdf-modal-close-btn"
            onClick={() => handleProductRanks(selectedProduct)}
          >
            Detailing Done
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductDetailing;
