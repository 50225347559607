import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to get MD list
export const reqToGetMDList = createAsyncThunk(
  "reqToGetMDList",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdList,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to search MD
export const reqToGetMDBySearch = createAsyncThunk(
  "reqToGetMDBySearch",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.searchMD,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get active cycle
export const reqToGetActiveCycle = createAsyncThunk(
  "reqToGetActiveCycle",
  async () => {
    try {
      const response = await Axios.get(apiendpoints.activeCycle, authHeaders());
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  mdList: [],
  activeCycleData: [],
};

// Create md list slice
const mdListSlice = createSlice({
  name: "mdList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get MD list
    builder.addCase(reqToGetMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get MD list by search
    builder.addCase(reqToGetMDBySearch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMDBySearch.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMDBySearch.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get patient details
    builder.addCase(reqToGetActiveCycle.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetActiveCycle.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.activeCycleData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetActiveCycle.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default mdListSlice.reducer;
