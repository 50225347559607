import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { cameraIcon, moreTwoDotsIcon } from "../../icon/icon";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import MatchSignature from "../../components/Common/Modals/MatchSignature";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToAddQuickSign,
  reqToGetQuickSignCount,
  reqToGetQuickSignlist,
} from "../../store/slice/quickSignSlice";
import Loader from "../../components/Common/Loader";
import { messages } from "../../helpers/messages";
import { reqToAddMediaFile } from "../../store/slice/callRecordingSlice";
import moment from "moment/moment";
import WebcamCapture from "../../components/Common/Modals/WebcamCapture";

const QuickSign = () => {
  const dispatch = useDispatch();

  // Get quickSign reducer data
  const quickSignReducer = useSelector((state) => state.quickSignReducer);
  const quickSignList = quickSignReducer && quickSignReducer.quickSignList;
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );

  // States
  const signPadRef = useRef();
  const webcamRef = useRef(null);
  const [signData, setSignData] = useState("");
  const [errors, setErrors] = useState({
    mdSign: "",
  });
  const [signatureModal, setSignatureModal] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState("");
  const [showListView, setShowListView] = useState(true);
  const [showWebcam, setShowWebcam] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [fileData, setFileData] = useState("");

  const handleShowSignList = () => {
    setShowListView(!showListView);
  };

  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };

  const clear = () => {
    if (fileData && fileData) {
      setFileUrl("");
      setFileData("");
    } else {
      signPadRef.current.clear();
      setSignData("");
    }
  };

  useEffect(() => {
    dispatch(reqToGetQuickSignCount());
    dispatch(reqToGetQuickSignlist());
  }, []);

  // Check validation
  const checkValidation = () => {
    if (!signData) {
      setErrors({
        ...errors,
        mdSign: messages.errors.sign_req,
      });
      return false;
    } else {
      setErrors({
        ...errors,
        mdSign: "",
      });
      return true;
    }
  };

  // Handle onchange draw signature
  const handleSignEnd = () => {
    if (!signPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        mdSign: "",
      });
    }
    setSignData(signPadRef.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  // Handle capture images
  const captureImage = () => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    if (imageSrc && imageSrc) {
      const blobData = dataURLtoBlob(imageSrc);
      setFileUrl(blobData && URL.createObjectURL(blobData));
      setFileData(blobData);
      handleShowWebcam();
    }
  };

  // Handle store signature media file
  const handleSaveSignature = () => {
    if (fileData && fileData) {
      const formData = new FormData();
      formData.append("file", fileData && fileData, "screenShort.png");
      handleFileUpload(formData);
    } else {
      if (checkValidation()) {
        let signDataCanvas = signPadRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        console.log("signDataCanvas", signDataCanvas);
        const blobData = dataURLtoBlob(signDataCanvas);
        const formData = new FormData();
        formData.append("file", blobData, "signature.png");
        handleFileUpload(formData);
      }
    }
  };

  // File upload
  const handleFileUpload = (formData) => {
    dispatch(
      reqToAddMediaFile({
        data: formData,
        onSuccess: (response) => {
          if (response && response?.data?.length > 0) {
            let imagesUrl =
              response && response?.data[0]?.imageUrlBigUrl.split("/");
            let imageName = imagesUrl && imagesUrl[imagesUrl?.length - 1];
            dispatch(
              reqToAddQuickSign({
                data: { signature_url: imageName },
                onSuccess: () => {
                  dispatch(reqToGetQuickSignlist());
                  handleShowSignList();
                  setFileUrl("");
                  setFileData("");
                  setSignData("");
                },
              })
            );
          }
        },
      })
    );
  };

  // Handle Match Signature and Add MD
  const handleMatchSignature = (item) => {
    setSelectedSignature(item);
    setSignatureModal(true);
  };

  return (
    <>
      {quickSignReducer && quickSignReducer.loader ? (
        <Loader />
      ) : (
        callRecordingReducer && callRecordingReducer.loader && <Loader />
      )}
      <div className="tc-body-section">
        <div className="tc-quicksign-content">
          <h2 className="tc-page-title">Quick Call</h2>
          <div className="tc-quicksign-total">
            <p className="tc-quicksign-number">
              Signatures to Match: {quickSignList && quickSignList?.count} / 10
              <button
                className="tc-quicksign-icon"
                onClick={() => handleShowSignList()}
              >
                {moreTwoDotsIcon}
              </button>
            </p>
          </div>
          {showListView ? (
            <>
              <div className="tc-quicksign-date-camera-row">
                <p className="tc-quicksign-date-time-text">
                  Date: {moment().format("lll")}
                </p>
                <button
                  className="tc-quicksign-camera-btn"
                  onClick={() => handleShowWebcam()}
                >
                  {cameraIcon}
                </button>
              </div>
              {fileData && fileData ? (
                <div className="tc-quicksign-signature-box tc-call-summary-photo-upload-box tc-quick-sign-signature-box">
                  <button
                    className="tc-quicksign-signature-erase-btn"
                    onClick={clear}
                  >
                    Erase
                  </button>
                  <img
                    className="tc-call-summary-photo-upload-img tc-quicksign-click-photo-img"
                    src={fileUrl && fileUrl}
                    alt="img"
                    style={{ objectFit: "contain" }}
                  />
                </div>
              ) : (
                <div className="tc-quicksign-signature-box tc-quick-sign-signature1-box">
                  <button
                    className="tc-quicksign-signature-erase-btn"
                    onClick={clear}
                  >
                    Erase
                  </button>
                  <SignaturePad
                    canvasProps={{ className: "tc-quicksign-signaturepad" }}
                    ref={signPadRef}
                    onEnd={() => handleSignEnd()}
                  />
                </div>
              )}
              <span className="tc-error">{errors && errors.mdSign}</span>
              <div className="tc-md-list-detail-bottom-btn-content">
                <button
                  type="button"
                  className="tc-md-list-detail-bottom-btn"
                  onClick={() => handleSaveSignature()}
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="row tc-quicksign-list-section">
                {quickSignList &&
                  quickSignList?.list?.length > 0 &&
                  quickSignList?.list.map((item, i) => {
                    return (
                      <div className="col-6 tc-quicksign-list-box" key={i}>
                        {item.date && (
                          <p className="text-left text-sm">
                            Date: {moment(item?.date).format("lll")}
                          </p>
                        )}
                        <div
                          className="tc-quicklist-canvas"
                          onClick={() => handleMatchSignature(item)}
                        >
                          {item.signature_url && (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                item.signature_url
                              }
                              alt="Signature"
                              draggable={false}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <MatchSignature
                show={signatureModal}
                selectedsignature={selectedSignature}
                onHide={() => setSignatureModal(false)}
              />
            </>
          )}
        </div>
      </div>
      {showWebcam && (
        <WebcamCapture
          show={showWebcam}
          webcamref={webcamRef}
          onHide={handleShowWebcam}
          captureimage={captureImage}
        />
      )}
    </>
  );
};

export default QuickSign;
