import React, { useState } from "react";
import { backBtnIcon } from "../../icon/icon";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";

function PostCall(props) {
  const { showPostCall, handleShowPostCall, handleAllCallRecordingData } =
    props;

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const callActivitieData =
    callRecordingReducer && callRecordingReducer.callActivitieData;
  const productDObj = callRecordingReducer && callRecordingReducer.productDObj;
  const marketRObj = callRecordingReducer && callRecordingReducer.marketRObj;
  const productSObj = callRecordingReducer && callRecordingReducer.productSObj;
  const jointCObj = callRecordingReducer && callRecordingReducer.jointCObj;

  const [postCallObj, setPostCallObj] = useState({
    call_id: "",
    signature_url: "",
    post_call: "",
    next_obj: "",
  });
  const [errors, setErrors] = useState({
    post_call: "",
    next_obj: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPostCallObj({
      ...postCallObj,
      [name]: value,
    });
    if (name === "next_obj" && value) {
      setErrors({
        ...errors,
        next_obj: "",
      });
    } else if (name === "post_call" && value) {
      setErrors({
        ...errors,
        post_call: "",
      });
    }
  };

  // Check validation
  const checkValidation = () => {
    if (!postCallObj.post_call && !postCallObj.next_obj) {
      setErrors({
        ...errors,
        post_call: messages.errors.post_call_req,
        next_obj: messages.errors.next_call_req,
      });
      return false;
    } else if (!postCallObj.post_call && postCallObj.next_obj) {
      setErrors({
        ...errors,
        post_call: messages.errors.post_call_req,
        next_obj: "",
      });
      return false;
    } else if (!postCallObj.next_obj && postCallObj.post_call) {
      setErrors({
        ...errors,
        post_call: "",
        next_obj: messages.errors.next_call_req,
      });
      return false;
    } else if (postCallObj.next_obj && postCallObj.next_obj) {
      setErrors({
        ...errors,
        post_call: "",
        next_obj: "",
      });
      return true;
    }
  };

  const handlePostCallErrors = () => {
    if (checkValidation()) {
      handleAllCallRecordingData(postCallObj);
    }
  };

  return (
    <>
      <div
        className={`tc-sidebar-section ${showPostCall && "zl-sidebar-open"}`}
      >
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleShowPostCall()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Post Call</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {callActivitieData &&
                  callActivitieData?.firstname +
                    " " +
                    callActivitieData?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData && callActivitieData?.specialty_name
                  ? callActivitieData?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {callActivitieData && callActivitieData?.location_name
                  ? callActivitieData?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {" "}
                {callActivitieData && callActivitieData?.target
                  ? handleVisits(
                      callActivitieData?.actual_visits,
                      callActivitieData?.target
                    )
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Objective:</span>
              <p className="tc-md-list-common-detail-text">
                {" "}
                {callActivitieData &&
                  callActivitieData?.objective &&
                  callActivitieData?.objective}
              </p>
            </div>
          </div>
          <Table className="tc-table tc-join-call-table">
            <thead>
              <tr>
                <th colSpan="2">ACTIVITIES DONE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tc-md-list-table-heading">Product Detailing</td>
                <td>
                  {productDObj &&
                    productDObj?.length > 0 &&
                    productDObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item.name}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Market Research</td>
                <td>
                  {marketRObj &&
                    marketRObj?.length > 0 &&
                    marketRObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item?.question} {item?.name}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Product Samples</td>
                <td>
                  {productSObj &&
                    productSObj?.length > 0 &&
                    productSObj.map((item, i) => {
                      return (
                        <p className="tc-md-list-table-paragraph" key={i}>
                          {item?.name}: {item?.quantity}
                        </p>
                      );
                    })}
                </td>
              </tr>
              <tr>
                <td className="tc-md-list-table-heading">Joint Call</td>
                <td>
                  <p className="tc-md-list-table-paragraph">
                    {`${
                      jointCObj && jointCObj?.name
                        ? "with" + " " + jointCObj?.name
                        : ""
                    }`}
                  </p>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="tc-md-list-behavior-input-box">
            <div className="tc-md-list-behavior-input-tectarea-box">
              <label className="tc-md-list-behavior-input-label">
                Post Call Analysis:
              </label>
              <textarea
                className="tc-md-list-behavior-input"
                name="post_call"
                value={postCallObj.post_call}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <span className="tc-error">{errors && errors?.post_call}</span>
            <div className="tc-md-list-behavior-input-tectarea-box">
              <label className="tc-md-list-behavior-input-label">
                Next Call Objective:
              </label>
              <textarea
                className="tc-md-list-behavior-input"
                name="next_obj"
                value={postCallObj.next_obj}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <span className="tc-error">{errors && errors?.next_obj}</span>
          </div>
          <div className="tc-md-list-detail-bottom-btn-content">
            <button
              type="button"
              className="tc-md-list-detail-bottom-btn"
              onClick={() => handlePostCallErrors()}
            >
              submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostCall;
