import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../../helpers/constants";
import {
  DashboardIcon,
  MdListIcon,
  OutOfFieldIcon,
  PromoMatsIcon,
  QuickSignIcon,
} from "../../icon/icon";

const Footer = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname !== routes.callActivities &&
        location.pathname !== routes.recivePromoMats && (
          <div className="tc-footer-section">
            <div className="tc-footer-content">
              <Link
                to={routes.dashboard}
                className={`tc-footer-tab ${
                  location.pathname === routes.dashboard && "active"
                }`}
              >
                {DashboardIcon}
                Dashboard
              </Link>
              <Link
                to={routes.mdList}
                className={`tc-footer-tab ${
                  location.pathname === routes.mdList && "active"
                }`}
              >
                {MdListIcon}
                Md List
              </Link>
              <Link
                to={routes.quickSign}
                className={`tc-footer-tab ${
                  location.pathname === routes.quickSign && "active"
                }`}
              >
                {QuickSignIcon}
                Quick Call
              </Link>
              <Link
                to={routes.promoMaterials}
                className={`tc-footer-tab ${
                  location.pathname == routes.promoMaterials && "active"
                }`}
              >
                {PromoMatsIcon}
                Promo Mats
              </Link>
              <Link
                to={routes.outOfField}
                className={`tc-footer-tab ${
                  location.pathname === routes.outOfField && "active"
                }`}
              >
                {OutOfFieldIcon}
                Out-Of-Field
              </Link>
            </div>
          </div>
        )}
    </>
  );
};
export default Footer;
