import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToAddMD,
  reqToGetQuickSignMDList,
} from "../../../store/slice/quickSignSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../helpers/constants";
import { messages } from "../../../helpers/messages";

const MatchSignature = (props) => {
  const { selectedsignature } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Get quickSign reducer data
  const quickSignReducer = useSelector((state) => state.quickSignReducer);
  const quickSignMDList = quickSignReducer && quickSignReducer.quickSignMDList;

  const [match, setMatch] = useState("existing");
  const [selectedMD, setSelectedMD] = useState("");
  const [newMDData, setNewMDData] = useState({
    firstname: "",
    lastname: "",
  });
  const [errors, setErrors] = useState({
    mdselect: "",
    mdname: "",
  });

  const handleSelect = (event) => {
    setMatch(event.target.value);
  };

  useEffect(() => {
    dispatch(reqToGetQuickSignMDList());
  }, []);

  // Create MD name option list
  const mdListOption =
    quickSignMDList &&
    quickSignMDList.map((item) => {
      return {
        ...item,
        value: item?.firstname + " " + item?.lastname,
        label: item?.firstname + " " + item?.lastname,
      };
    });

  // Handle validation
  const checkValidation = () => {
    if (match === "existing") {
      if (selectedMD === "") {
        setErrors({
          ...errors,
          mdselect: messages.errors.md_select_req,
        });
      } else {
        return true;
      }
    } else {
      if (newMDData.firstname === "" || newMDData.lastname === "") {
        setErrors({
          ...errors,
          mdname: messages.errors.md_name_req,
        });
      } else {
        return true;
      }
    }
  };

  // Handle on change values
  const handleChangeValues = (event, type) => {
    if (type === "option") {
      setSelectedMD(event);
      setErrors({
        ...errors,
        mdselect: "",
      });
    } else {
      const { name, value } = event.target;
      setNewMDData({ ...newMDData, [name]: value });
      setErrors({
        ...errors,
        mdname: "",
      });
    }
  };

  // Handle md and navigate activite page
  const handleMatchMDSignature = () => {
    const tempObj = {
      signature_id: selectedsignature?.id,
      signature_url: selectedsignature?.signature_url,
      isQuick: true,
    };
    if (checkValidation()) {
      if (match === "existing") {
        props.onHide();
        navigate(routes.callActivities, {
          state: {
            selectedMD: selectedMD,
            reqObj: { md_id: selectedMD?.id },
            prevPath: location.pathname,
            quickSignObj: tempObj,
          },
        });
      } else {
        dispatch(
          reqToAddMD({
            data: newMDData,
            onSuccess: (response) => {
              props.onHide();
              navigate(routes.callActivities, {
                state: {
                  selectedMD: response,
                  reqObj: { md_id: response?.id },
                  prevPath: location.pathname,
                  quickSignObj: tempObj,
                },
              });
            },
          })
        );
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        className="tc-main-modal tc-quicksign-modal"
        size="lg"
        centered
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title>Match Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-2">
            <div className="tc-quicklist-canvas">
              <img
                src={
                  selectedsignature &&
                  process.env.REACT_APP_IMAGE_URL +
                    selectedsignature?.signature_url
                }
                alt="Signature"
              />
            </div>
            <div className="tc-quicksign-radio row">
              <p className="col-4">Match to:</p>
              <Form className="col-8">
                <div className="mb-3 flex">
                  <Form.Check
                    inline
                    id="existing"
                    name="countries"
                    value="existing"
                    label="Existing"
                    type={"radio"}
                    onChange={handleSelect}
                    defaultChecked
                  />
                  <Form.Check
                    inline
                    id="incidental"
                    name="countries"
                    value="incidental"
                    label="Incidental"
                    type={"radio"}
                    checked={match === "incidental"}
                    onChange={handleSelect}
                    disabled
                  />
                </div>
              </Form>
            </div>
            {match === "existing" ? (
              <div className="row">
                <p className="col-4">MD Name:</p>
                <div className="col-8">
                  <Select
                    name="option"
                    defaultValue={selectedMD}
                    onChange={(e) => handleChangeValues(e, "option")}
                    options={mdListOption}
                    className="tc-custom-select"
                    classNamePrefix="select"
                    placeholder="Select"
                  />
                  <span className="tc-error">{errors && errors.mdselect}</span>
                </div>
              </div>
            ) : (
              <div>
                <div className="row mb-3">
                  <p className="col-4">MD Last Name:</p>
                  <div className="col-8">
                    <Form.Control
                      type="text"
                      id="base"
                      name="firstname"
                      defaultValue="De Guzman"
                      value={newMDData.firstname}
                      onChange={(e) => handleChangeValues(e, "input")}
                    />
                  </div>
                </div>
                <div className="row">
                  <p className="col-4">MD First Name:</p>
                  <div className="col-8">
                    <Form.Control
                      type="text"
                      id="base"
                      name="lastname"
                      defaultValue="Mark Anthony"
                      value={newMDData.lastname}
                      onChange={(e) => handleChangeValues(e, "input")}
                    />
                  </div>
                </div>
                <span className="tc-error">{errors && errors.mdname}</span>
              </div>
            )}
            <div className="text-center">
              <Button
                className="tc-save"
                onClick={() => handleMatchMDSignature()}
              >
                {" "}
                Record Call Activities{" "}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MatchSignature;
