import React, { useEffect, useState } from "react";
import { backBtnIcon } from "../../icon/icon";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import DataNotFound from "../../components/Common/DataNotFound";
import {
  productSObjHandler,
  updateMDBehavior,
} from "../../store/slice/callRecordingSlice";
import { messages } from "../../helpers/messages";

const ProductSampling = (props) => {
  const { showsidebar, handleCallActivitiesSidebar, updateCallActivitieData } =
    props;
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const productSamplesData =
    callRecordingReducer && callRecordingReducer.productSamplesData;
  const activities = callRecordingReducer && callRecordingReducer.activities;
  const productSObj = callRecordingReducer && callRecordingReducer.productSObj;
  const mdBehavior = callRecordingReducer && callRecordingReducer.mdBehavior;

  // States
  const [errors, setErrors] = useState("");
  const [behavior, setBehavior] = useState(mdBehavior);
  const [productSamples, setProductSamples] = useState([]);
  const [productSamplesObj, setProductSamplesObj] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);

  // Customize productSamples data array
  useEffect(() => {
    if (
      productSamplesData &&
      productSamplesData?.product_sampling?.length > 0 &&
      productSObj?.length === 0
    ) {
      const newSamplesData = productSamplesData.product_sampling.map((item) => {
        return {
          ...item,
          quantity: "",
        };
      });
      setProductSamples(newSamplesData);
      setErrors("");
    }
    setBehavior(mdBehavior && mdBehavior ? mdBehavior : "");
  }, [callRecordingReducer]);

  // Update callActivitie data and Close page
  useEffect(() => {
    if (productSObj && productSObj.length > 0) {
      handleCallActivitiesSidebar();
      updateCallActivitieData(activities.productS);
    }
  }, [productSObj]);

  // Check validation
  const checkValidation = () => {
    if (
      productSamplesObj &&
      productSamplesObj?.length === 0 &&
      behavior === ""
    ) {
      setErrors(messages.errors.sample_behav_req);
      return false;
    } else if (productSamplesObj && productSamplesObj.length === 0) {
      setErrors(messages.errors.sample_req);
      return false;
    } else if (behavior === "") {
      setErrors(messages.errors.behavior_req);
      return false;
    } else {
      setErrors("");
      return true;
    }
  };
  // Handle onChange
  const handleChangeQty = (event, data) => {
    let value = Number(event.target.value);
    let newData = [...productSamplesObj];
    let filterData =
      productSamplesObj &&
      productSamplesObj?.find((product) => product.promomat_id === data.id);
    if (filterData) {
      let index =
        newData &&
        newData.findIndex(
          (item) => item.promomat_id === filterData.promomat_id
        );
      newData[index] = {
        promomat_id: data.id,
        quantity: value,
        name: data.name,
      };
      setChangeStatus(true);
      setProductSamplesObj(newData);
      updateproductSamplesData(value, data);
    } else {
      newData.push({
        promomat_id: data.id,
        quantity: value,
        name: data.name,
      });
      setProductSamplesObj(newData);
      updateproductSamplesData(value, data);
    }
  };

  // Handle MD behavior
  const handleChangeMDBehavior = (event) => {
    setBehavior(event.target.value);
  };

  // Update productSamples data
  const updateproductSamplesData = (value, data) => {
    const newProductSamplesData =
      productSamples &&
      productSamples.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            quantity: value,
          };
        } else {
          return item;
        }
      });
    setProductSamples(newProductSamplesData);
  };

  // Update productSamples data object
  const handleProductSamplesDataObj = () => {
    if (checkValidation()) {
      if (
        (productSamplesObj &&
          productSamplesObj?.length !== productSObj?.length) ||
        changeStatus
      ) {
        dispatch(productSObjHandler(productSamplesObj));
        dispatch(updateMDBehavior(behavior));
        setChangeStatus(false);
      } else if (mdBehavior && mdBehavior !== behavior) {
        dispatch(updateMDBehavior(behavior));
        handleCallActivitiesSidebar();
      } else {
        handleCallActivitiesSidebar();
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showsidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Product Sampling</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {productSamplesData &&
                  productSamplesData?.firstname +
                    " " +
                    productSamplesData?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {productSamplesData && productSamplesData?.specialty_name
                  ? productSamplesData?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {productSamplesData && productSamplesData?.location_name
                  ? productSamplesData?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {productSamplesData && productSamplesData?.target
                  ? handleVisits(
                      productSamplesData?.actual_visits,
                      productSamplesData?.target
                    )
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Objective:</span>
              <p className="tc-md-list-common-detail-text">
                {productSamplesData &&
                  productSamplesData?.objective &&
                  productSamplesData?.objective}
              </p>
            </div>
          </div>
          <Table className="tc-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>QTY</th>
              </tr>
            </thead>
            <tbody>
              {productSamples && productSamples?.length === 0 ? (
                <tr>
                  <td colSpan="2">
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                <>
                  {productSamples &&
                    productSamples?.length > 0 &&
                    productSamples.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td className="tc-md-list-table-heading">
                            {item?.name}
                          </td>
                          <td>
                            <input
                              type="number"
                              className="tc-md-list-table-qty-input"
                              value={item?.quantity > 0 && item?.quantity}
                              onChange={(e) => handleChangeQty(e, item)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
          {productSamples && productSamples?.length > 0 && (
            <>
              <div className="tc-md-list-behavior-input-box">
                <label className="tc-md-list-behavior-input-label">
                  MD Behavior:
                </label>
                <textarea
                  className="tc-md-list-behavior-input"
                  value={behavior}
                  onChange={(e) => handleChangeMDBehavior(e)}
                  spellCheck="false"
                ></textarea>
              </div>
              <span className="tc-error">{errors && errors}</span>
              <div className="tc-md-list-detail-bottom-btn-content">
                <button
                  type="button"
                  className="tc-md-list-detail-bottom-btn"
                  onClick={() => handleProductSamplesDataObj()}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductSampling;
