import React from "react";
import dataNotFound from "../../images/DataNotFound.svg";
import { messages } from "../../helpers/messages";

const DataNotFound = (props) => {
  return (
    <div className="tc-datanot-found">
      <img src={dataNotFound} alt={messages.toast.nodata} />
      <p>{props?.name ? props.name : messages.toast.nodata}</p>
    </div>
  );
};

export default DataNotFound;
