import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/style.css";
import getRoutes from "./routes";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";

const App = () => {
  const localSUser = JSON.parse(localStorage.getItem("user"));
  const user = useSelector((state) => state.userReducer.user);
  const routing = useRoutes(getRoutes(localSUser ? user : null));
  return <>{routing}</>;
};

export default App;
