import { toast } from "react-toastify";

const toastList = new Set();
const MAX_TOAST = 1;

// One time toast message show //
export const notify = (test, message) => {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
};

// Days name array
var dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Month name array
const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dateFormatConvertor = (date, month, year) => {
  let dd = date < 10 ? "0" + date : date;
  let mm = month < 10 ? "0" + month : month;
  let yyyy = year;
  return yyyy + "-" + mm + "-" + dd;
};

// Get start date, End date, Month weeks
export const getStartDateAndEndDate = (type, week, month, defDate) => {
  // Get current, start and End date
  const currDate = new Date();
  const currYear = currDate.getFullYear();
  const currMonth =
    typeof month !== "number" ? monthArray.indexOf(month) : month;
  const startDate = new Date(currYear, currMonth, 1);
  const endDate = new Date(currYear, currMonth + 1, 0);

  if (type === "day") {
    const weeks = [];
    const numDays = endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;
    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }
    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w) => ({
        start: dateFormatConvertor(w[0], currMonth + 1, currYear),
        end: dateFormatConvertor(w[w.length - 1], currMonth + 1, currYear),
        dates: w.map((d, i) => {
          return {
            date: dateFormatConvertor(d, currMonth + 1, currYear),
            day: dayNames[i],
          };
        }),
      }));
    return {
      report: type,
      startDate: weekData[week - 1]?.start,
      endDate: weekData[week - 1]?.end,
      dates: weekData[week - 1].dates,
      week: week,
    };
  } else if (type === "week") {
    const weeks = [];
    const numDays = defDate ? defDate : endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;

    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w, i) => ({
        dates: w,
        weekDetails: {
          id: i + 1,
          label: `Week ${i + 1}`,
          value: i + 1,
        },
      }));
    return {
      report: type,
      weekData: weekData,
      month: monthArray[month],
      year: currYear,
    };
  } else if (type === "month") {
    const months = [];
    const numMonths = month;
    for (let i = 0; i <= numMonths; i++) {
      months.push(monthArray[i]);
    }
    const monthData = months
      .filter((m) => m.length !== 0)
      .map((m, i) => ({
        monthDetails: { id: i + 1, lable: m, value: m },
      }));
    return {
      report: type,
      month: monthData,
    };
  } else if (type === "monthDates") {
    return { startDate: startDate, endDate: endDate };
  }
};

// Handle number formatting
export const numberFormating = (number) => {
  return new Intl.NumberFormat().format(number && number);
};

// Handle visits like (a of b)
export const handleVisits = (actual, target) => {
  if (target > 0) {
    return actual > 0 ? actual + " of " + target : 0 + " of " + target;
  } else {
    return 0 + " of " + 0;
  }
};
