import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to get quickSign count Data
export const reqToGetQuickSignCount = createAsyncThunk(
  "reqToGetQuickSignCount",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignCount,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get quickSign list Data
export const reqToGetQuickSignlist = createAsyncThunk(
  "reqToGetQuickSignlist",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignlist,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get quickSign MD list list Data
export const reqToGetQuickSignMDList = createAsyncThunk(
  "reqToGetQuickSignMDList",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignmdList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add QuickSign
export const reqToAddQuickSign = createAsyncThunk(
  "reqToAddQuickSign",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addQuickSign,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add MD
export const reqToAddMD = createAsyncThunk(
  "reqToAddMD",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addMD,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to Update quickSign
export const reqToUpdateQuickSign = createAsyncThunk(
  "reqToUpdateQuickSign",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.quickSignUpdate,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  quickSignCount: "",
  quickSignList: [],
  quickSignMDList: [],
};

// Create quickSign slice
const quickSignSlice = createSlice({
  name: "quickSignSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get quickSignCount data
    builder.addCase(reqToGetQuickSignCount.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignCount.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.quickSignCount = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignCount.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get quickSignCount data
    builder.addCase(reqToGetQuickSignlist.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignlist.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.quickSignList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignlist.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get quickSignCount data
    builder.addCase(reqToGetQuickSignMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.quickSignMDList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add quickSign
    builder.addCase(reqToAddQuickSign.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddQuickSign.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddQuickSign.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add MD
    builder.addCase(reqToAddMD.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMD.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMD.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default quickSignSlice.reducer;
