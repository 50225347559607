import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

import {
  SearchIcon,
  checkBorderIcon,
  rightCircleFillIcon,
} from "../../icon/icon";
import { routes } from "../../helpers/constants";
import Loader from "../../components/Common/Loader";
import doctorIcons from "../../images/doctorIcons.png";
import DataNotFound from "../../components/Common/DataNotFound";

// APIs
import {
  reqToGetActiveCycle,
  reqToGetMDBySearch,
  reqToGetMDList,
} from "../../store/slice/mdListSlice";
import { handleVisits } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";

const MdList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Get MD list reducer data
  const mdListReducer = useSelector((state) => state.mdListReducer);
  const mdListData = mdListReducer && mdListReducer.mdList;
  const activeCycleData = mdListReducer && mdListReducer.activeCycleData;

  // States
  const currentDate = moment().format("YYYY-MM-DD");
  const [selectedDate, setselectedDate] = useState(currentDate);
  const [searchValue, setSearchValue] = useState("");

  // Get default MD list and Active cycle data
  useEffect(() => {
    searchMDListByDate("date", selectedDate);
    dispatch(reqToGetActiveCycle());
  }, []);

  // Handle onChange search MD list
  const handleOnChange = (event) => {
    if (event.target.value === "") {
      setSearchValue(event.target.value);
      setselectedDate(currentDate);
      dispatch(reqToGetMDList({ data: { report_date: currentDate } }));
    } else {
      setSearchValue(event.target.value);
    }
  };

  // Search MD list by date
  const searchMDListByDate = (searchby, data) => {
    if (searchby === "date") {
      setselectedDate(data ? data : currentDate);
      dispatch(
        reqToGetMDList({ data: { report_date: data ? data : currentDate } })
      );
    } else {
      dispatch(reqToGetMDBySearch({ data: { keyword: searchValue } }));
    }
  };

  // Handle call Activities of MD
  const handleCallActivitiesOfMD = (item) => {
    if (item && item.md_id) {
      navigate(routes.callActivities, {
        state: {
          selectedMD: item,
          reqObj: { md_id: item.md_id },
          prevPath: location.pathname,
        },
      });
    }
  };

  // Data table
  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => <p className="tc-data-table-class-text">{row?.name}</p>,
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <p className="tc-data-table-md-name-text text-start">
          {row?.firstname.concat(" ", row?.lastname)}
        </p>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p className="tc-data-table-visits-text tc-data-table-green-text">
          {typeof row?.actual_visits === "number" ? row?.actual_visits : 0} of{" "}
          {row?.target}
        </p>
      ),
    },
    {
      name: "Call",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) =>
        row.is_visited === 0 ? (
          <button
            className="tc-data-table-button"
            onClick={() => handleCallActivitiesOfMD(row)}
          >
            {rightCircleFillIcon}
          </button>
        ) : (
          <div className="tc-data-table-button-check-icon">
            {checkBorderIcon}
          </div>
        ),
    },
  ];

  return (
    <>
      {mdListReducer && mdListReducer.loader && <Loader />}
      <div className="tc-body-section">
        <div className="tc-calender-search-input-row">
          <div className="tc-calender-input-content">
            <DatePicker
              selected={moment(selectedDate)._d}
              onChange={(e) =>
                searchMDListByDate("date", moment(e).format("YYYY-MM-DD"))
              }
              dateFormat="yyyy-MM-dd"
              value={selectedDate}
              minDate={moment(activeCycleData && activeCycleData?.start)._d}
              maxDate={moment(activeCycleData && activeCycleData?.end)._d}
              onKeyDown={(e) => e.preventDefault()}
              disabled={searchValue !== ""}
            />
          </div>
          <div className="tc-search-input-content">
            <input
              id="text"
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => handleOnChange(e)}
            />
            <button
              type="button"
              className="tc-search-input-btn"
              onClick={() => searchMDListByDate()}
              disabled={searchValue === "" && true}
            >
              {SearchIcon}
            </button>
          </div>
        </div>
        <div className="tc-doctor-img-date-text">
          <img src={doctorIcons} alt="doctor" />
          <p>{moment().format("LL")}</p>
        </div>
        {mdListData && mdListData?.length === 0 ? (
          <DataNotFound name={messages.toast.nodateData} />
        ) : (
          <DataTable
            columns={columns}
            data={mdListData && mdListData}
            className="tc-data-table"
          />
        )}
      </div>
    </>
  );
};

export default MdList;
