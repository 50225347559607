import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to get out-of-field Data
export const reqToGetOutOfFieldData = createAsyncThunk(
  "reqToGetOutOfFieldData",
  async () => {
    try {
      const response = await Axios.get(apiendpoints.outOfField, authHeaders());
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get out-of-field Data
export const reqToGetModalData = createAsyncThunk(
  "reqToGetModalData",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.outOfFieldModalData,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to updateMaterial
export const addOutOfFieldData = createAsyncThunk(
  "addOutOfFieldData",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.addOutOfField,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  outOfFieldData: [],
  modalData: {},
  addComponent: "",
};

// Create out-of-field slice
const outOfFieldSlice = createSlice({
  name: "out-of-field",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get out-of-field Data
    builder.addCase(reqToGetOutOfFieldData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetOutOfFieldData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.outOfFieldData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetOutOfFieldData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get out-of-field Data
    builder.addCase(reqToGetModalData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetModalData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.modalData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetModalData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Submit promomaterials Receive
    builder.addCase(addOutOfFieldData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addOutOfFieldData.fulfilled, (state, action) => {
      state.loader = false;
      state.addComponent = action.payload;
    });
    builder.addCase(addOutOfFieldData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default outOfFieldSlice.reducer;
