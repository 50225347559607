import React, { useEffect, useRef, useState } from "react";
import { backBtnIcon } from "../../icon/icon";
import { Table } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import DataNotFound from "../../components/Common/DataNotFound";
import {
  jointCallObjHandler,
  reqToAddMediaFile,
} from "../../store/slice/callRecordingSlice";
import moment from "moment";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { messages } from "../../helpers/messages";

const JointCall = (props) => {
  const { showsidebar, handleCallActivitiesSidebar, updateCallActivitieData } =
    props;
  const dispatch = useDispatch();

  // Get calRecording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const jointCallData =
    callRecordingReducer && callRecordingReducer.jointCallData;
  const activities = callRecordingReducer && callRecordingReducer.activities;
  const jointCObj = callRecordingReducer && callRecordingReducer.jointCObj;

  // Statas
  const signPadRef = useRef();
  const [changeStatus, setChangeStatus] = useState(false);
  const [mdData, setMdData] = useState("Select");
  const [noteData, setNoteData] = useState("");
  const [signData, setSignData] = useState("");
  const [errors, setErrors] = useState({
    joinCall: "",
    dmSign: "",
  });

  const clear = () => {
    signPadRef?.current?.clear();
    setSignData("");
  };

  // Update error messages
  useEffect(() => {
    if (Object.keys(jointCObj && jointCObj)?.length === 0) {
      setMdData("Select");
      setNoteData("");
      clear();
      setErrors({
        ...errors,
        joinCall: "",
        dmSign: "",
      });
    }
  }, [jointCallData]);

  // Update jointCall data and Close page
  useEffect(() => {
    if (Object.keys(jointCObj).length > 0) {
      handleCallActivitiesSidebar();
      updateCallActivitieData(activities.jointCall);
    }
  }, [jointCObj]);

  // Check validation
  const checkValidation = () => {
    if (mdData === "Select" && !signData) {
      setErrors({
        ...errors,
        joinCall: messages.errors.join_call_req,
        dmSign: messages.errors.sign_req,
      });
      return false;
    } else if (mdData === "Select" && signData) {
      setErrors({
        ...errors,
        joinCall: messages.errors.join_call_req,
        dmSign: "",
      });
      return false;
    } else if (!signData && mdData) {
      setErrors({
        ...errors,
        joinCall: "",
        dmSign: messages.errors.sign_req,
      });
      return false;
    } else if (mdData && signData) {
      setErrors({
        ...errors,
        joinCall: "",
        dmSign: "",
      });
      return true;
    }
  };

  // Handle jointCall values
  const handleJointCallValues = (event) => {
    const { name, value } = event.target;
    setChangeStatus(true);
    if (name === "option") {
      const newData =
        jointCallData &&
        jointCallData?.joint_call.find((item) => item.id === Number(value));
      setMdData(newData);
      setErrors({
        ...errors,
        joinCall: "",
      });
      setErrors({
        ...errors,
        joinCall: "",
      });
    } else if (name === "notes") {
      setNoteData(value);
    }
  };

  // Handle onchange sign pad
  const handleSignEnd = () => {
    if (!signPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        dmSign: "",
      });
    }
    setChangeStatus(true);
    setSignData(signPadRef.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  // Update jointCall data object
  const handleJointCallDataObj = () => {
    if (checkValidation()) {
      if (changeStatus) {
        let signDataCanvas = signPadRef.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        const blobData = dataURLtoBlob(signDataCanvas);
        const fd = new window.FormData();
        fd.append("file", blobData, "signature.png");
        dispatch(
          reqToAddMediaFile({
            data: fd,
            onSuccess: (response) => {
              if (response && response?.data?.length > 0) {
                let imagesUrl =
                  response && response?.data[0]?.imageUrlBigUrl.split("/");
                let imageName = imagesUrl && imagesUrl[imagesUrl?.length - 1];
                const tempObj = {
                  notes_id: mdData.id,
                  name: mdData.name,
                  notes: noteData,
                  signature_url: imageName,
                };
                dispatch(jointCallObjHandler(tempObj));
                setChangeStatus(false);
              }
            },
          })
        );
      } else {
        handleCallActivitiesSidebar();
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showsidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Joint Call</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {jointCallData &&
                  jointCallData?.firstname + " " + jointCallData?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {jointCallData && jointCallData?.specialty_name
                  ? jointCallData?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {jointCallData && jointCallData?.location_name
                  ? jointCallData?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {jointCallData && jointCallData?.target
                  ? handleVisits(
                      jointCallData?.actual_visits,
                      jointCallData?.target
                    )
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Objective:</span>
              <p className="tc-md-list-common-detail-text">
                {jointCallData &&
                  jointCallData?.objective &&
                  jointCallData?.objective}
              </p>
            </div>
          </div>
          <Table className="tc-table tc-join-call-table">
            <thead>
              <tr>
                <th>Question</th>
                <th>Answer</th>
              </tr>
            </thead>
            <tbody>
              {jointCallData && jointCallData?.joint_call?.length === 0 ? (
                <tr>
                  <td colSpan="2">
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td className="tc-md-list-table-paragraph">
                      Who joined this call?
                    </td>
                    <td>
                      <select
                        className="tc-md-list-table-question-answer-select"
                        value={mdData.id ? mdData.id : "Select"}
                        name="option"
                        onChange={(e) => handleJointCallValues(e)}
                      >
                        <option value="Select" disabled>
                          Select
                        </option>
                        {jointCallData &&
                          jointCallData?.joint_call?.length > 0 &&
                          jointCallData?.joint_call.map((option, i) => {
                            return (
                              <option key={i} value={option.id}>
                                {option?.name}
                              </option>
                            );
                          })}
                      </select>
                      <span className="tc-md-list-table-td-error">
                        {errors && errors?.joinCall}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="tc-md-list-table-paragraph">Notes</td>
                    <td>
                      <textarea
                        className="tc-md-list-table-question-answer-textarea"
                        name="notes"
                        value={noteData}
                        spellCheck="false"
                        onChange={(e) => handleJointCallValues(e)}
                      ></textarea>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
          {jointCallData && jointCallData?.joint_call?.length > 0 && (
            <>
              <div className="tc-md-list-behavior-input-box">
                <label className="tc-md-list-behavior-input-label">
                  Signature:
                </label>
                <div className="tc-call-summary-signature-box tc-joint-call-signature-box">
                  <button
                    className="tc-call-summary-signature-erase-btn"
                    onClick={clear}
                  >
                    Erase
                  </button>
                  <SignaturePad
                    canvasProps={{
                      className: "tc-join-call-digi-signaturepad",
                    }}
                    ref={signPadRef}
                    onEnd={() => handleSignEnd()}
                  />
                </div>
                {errors && errors?.dmSign && (
                  <span className="tc-error">{errors && errors?.dmSign}</span>
                )}
                <p className="tc-signaturepad-time-text">
                  {moment().format("lll")}
                </p>
              </div>
              <div className="tc-md-list-detail-bottom-btn-content">
                <button
                  type="button"
                  className="tc-md-list-detail-bottom-btn"
                  onClick={() => handleJointCallDataObj()}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default JointCall;
