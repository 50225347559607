import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { backBtnIcon } from "../../icon/icon";
import { useNavigate } from "react-router-dom";
import AdjustQuantity from "../../components/Common/Modals/AdjustQuantity";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToGetAdjustReasons,
  reqToGetReciveMatList,
  submitReceiveMats,
} from "../../store/slice/promoMaterialSlice";
import Loader from "../../components/Common/Loader";
import { adjustPromoMatsValidations } from "../../helpers/formValidations";
import { useForm } from "../../hooks/useForm";
import moment from "moment";

const ReceivePromoMats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [materialObj, setMaterialObj] = useState({});
  const [isRecivedMats, setIsRecivedMats] = useState(0);

  const returnMatsList = useSelector(
    (state) => state.promoMaterialReducer.receivedMatsList
  );
  const is_received = useSelector(
    (state) => state.promoMaterialReducer.is_received
  );

  const adjustReasons = useSelector(
    (state) => state.promoMaterialReducer.adjustReasons
  );

  const isLoading = useSelector((state) => state.promoMaterialReducer.loader);

  const [newReceivedmatsList, setNewReceivedmatsList] = useState();

  const removeNameFromMats = (arr) => {
    return arr.map((obj) => {
      const { name, actual_qty, ...rest } = obj;
      return { ...rest, received_qty: actual_qty };
    });
  };

  useEffect(() => {
    setNewReceivedmatsList(
      returnMatsList &&
        returnMatsList?.map((item) => {
          return {
            ...item,
            // received_qty: item.issued_qty,
            // notes_id: null,
          };
        })
    );
    setIsRecivedMats(is_received);
  }, [returnMatsList, is_received]);

  // Initial adjust form values
  const initialFValues = {
    issued_qty: "",
    received_qty: "",
    reason: "",
  };

  useEffect(() => {
    setValues({
      ...values,
      issued_qty:
        materialObj && materialObj.issued_qty ? materialObj.issued_qty : "",
      received_qty:
        materialObj && materialObj.actual_qty ? materialObj.actual_qty : "",
      notes_id: "",
    });
  }, [materialObj]);

  useEffect(() => {
    dispatch(reqToGetReciveMatList());
    dispatch(reqToGetAdjustReasons());
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return adjustPromoMatsValidations(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  //

  const updateActualNumber = (array, promomatId, newActualNumber, reasonId) => {
    const updatedArray = array.map((obj) => {
      if (obj.promomat_id === promomatId) {
        return { ...obj, actual_qty: +newActualNumber, notes_id: +reasonId };
      }
      return obj;
    });
    return updatedArray;
  };

  const saveAdjustMatsHandler = () => {
    if (validate()) {
      const data = {
        promomat_id: materialObj.promomat_id,
        received_qty: +values.received_qty,
      };
      setNewReceivedmatsList(
        updateActualNumber(
          newReceivedmatsList,
          materialObj.promomat_id,
          values.received_qty,
          values.reason
        )
      );
      setModalShow(false);
    }
  };

  const getReasonNameById = (notes_id) => {
    const foundReason = adjustReasons?.find((reason) => reason.id === notes_id);
    return foundReason ? foundReason.name : "";
  };

  const onSubmitHandler = () => {
    const data = {
      materials: removeNameFromMats(newReceivedmatsList),
    };

    dispatch(
      submitReceiveMats({
        data,
        cb: (err, res) => {
          if (err) {
            console.log("err", err);
          } else {
            dispatch(reqToGetReciveMatList());
            setValues({
              issued_qty: "",
              received_qty: "",
              reason: "",
            });
          }
        },
      })
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body-section tc-receive-pro-matr-body-section">
        {/* <div className="tc-sidebar-navbar">
        <button
          type="button"
          className="tc-sidebar-back-btn"
          onClick={() => navigate(-1)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-sidebar-title">Receive Promo Materials</p>
        <div className="tc-sidebar-right-btn"></div>
      </div> */}
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">Receive Promo Materials</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="tc-promo-edit-table-content">
          <div className="tc-promo-edit-table-text">
            <p>
              Date: <span>{moment(new Date()).format("LL")}</span>
            </p>
            <p>
              Total: <span>{newReceivedmatsList?.length}</span>
            </p>
          </div>
          <div className="tc-table-content">
            <Table className="tc-table tc-daily-table">
              <thead>
                <tr>
                  <th className="text-start">Promo Materials</th>
                  <th>RECEIVED</th>
                  <th>ACTUAL</th>
                  <th>REASON</th>
                </tr>
              </thead>
              <tbody>
                {newReceivedmatsList?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start tc-md-list-table-heading">
                        {item.name}
                      </td>
                      <td>{item.issued_qty}</td>
                      <td>
                        {item.actual_qty}
                        <div
                          className="tc-sidebar-btn"
                          variant="primary"
                          onClick={() => {
                            setModalShow(true);
                            setMaterialObj(item);
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </div>
                      </td>
                      <td>
                        {item.notes_id ? getReasonNameById(item.notes_id) : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          {/* {is_received === 0 && ( */}
          <button
            type="button"
            className="tc-save"
            onClick={() => onSubmitHandler()}
            disabled={is_received === 1}
          >
            SUBMIT
          </button>
          {/* )} */}
          <AdjustQuantity
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setMaterialObj({});
              setValues({
                issued_qty: "",
                received_qty: "",
                reason: "",
              });
              setErrors({});
            }}
            materialObj={materialObj}
            values={values}
            handleInputChange={handleInputChange}
            errors={errors}
            saveAdjustMatsHandler={saveAdjustMatsHandler}
          />
        </div>
      </div>
    </>
  );
};

export default ReceivePromoMats;
