import React from "react";
import { useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { routes } from "../../helpers/constants";
import userDefault from "../../images/UserDefaultImage.png";
import { logoutIcon } from "../../icon/icon";
import { useDispatch } from "react-redux";
import { reqToUserLogout } from "../../store/slice/userSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  // Handle user logout
  const handleUserLogout = () => {
    dispatch(reqToUserLogout());
  };

  return (
    <>
        {location.pathname !== routes.callActivities &&
          location.pathname !== routes.recivePromoMats && (
        <div className="tc-navbar-section">
          <div className="tc-navbar-content">
            <span className="tc-navbar-user-name">
              {/* {userReducer &&
                userReducer.firstname + " " + userReducer.lastname} */}
                dktHealth
            </span>
            <Dropdown className="tc-navbar-profile-dropdown">
                <Dropdown.Toggle>
                  {userReducer &&
                    userReducer.firstname + " " + userReducer.lastname}
                  <img src={userDefault} alt="userPhoto" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <button
                    type="button"
                    className="tc-navbar-profile-logout-item dropdown-item"
                    onClick={() => handleUserLogout()}
                  >
                    {logoutIcon}
                    Logout
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
    </>
  );
};
export default Navbar;
