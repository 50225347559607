import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import moment from "moment";
import DatePicker from "react-datepicker";

const RecordOutField = (props) => {
  const {
    dateCycle,
    show,
    typeList,
    reasonsList,
    values,
    errors,
    handleInputChange,
    setValues,
    onAddRecordHandler,
  } = props;

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Record Out-Of-Field
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row mb-3">
              <p className="col-4">Type: </p>
              <div className="col-8">
                <Form.Select
                  name="oof_type_id"
                  id="oof_type_id"
                  onChange={(e) => handleInputChange(e, "oof_type_id")}
                >
                  {typeList?.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Date:</p>
              <div className="col-8">
                <DatePicker
                  onChange={(e) => {
                    setValues({ ...values, date: e });
                  }}
                  id="date"
                  name="date"
                  dateFormat="yyyy-MM-dd"
                  value={values.date}
                  selected={values.date}
                  minDate={moment(dateCycle?.start)._d}
                  maxDate={moment(dateCycle?.end)._d}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Reason:</p>
              <div className="col-8">
                <Form.Select
                  name="notes_id"
                  id="notes_id"
                  onChange={(e) => handleInputChange(e, "notes_id")}
                >
                  {reasonsList?.map((item, i) => {
                    return (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button className="tc-save" onClick={() => onAddRecordHandler()}>
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RecordOutField;
